








import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import api from "@/api";

@Component({
  name: "AgileAuditTag",
})
export default class AgileAuditTag extends Vue {
  @Prop({ required: false, default: {} }) item!: any;

  @Prop({ required: false, default: "" })
  size!: string;

  get tagType() {
    // this.item.auditStatus === this.item.audit

    if (this.getIsSuccess()) {
      return "success";
    } else if (this.item.audit === -1) {
      return "danger";
    } else if (this.item.audit === null) {
      return "info";
    } else {
      return "";
    }
  }

  get tagText() {
    if (this.getIsSuccess()) {
      return "已通过";
    } else if (this.item.audit === -1) {
      return "未通过";
    } else if (this.item.audit === null) {
      return "未开始";
    } else {
      return `审核中 ${
        this.item.auditStatus === null ? 0 : this.item.auditStatus + 1
      }/${this.item.audit + 1}`;
    }
  }

  getIsSuccess() {
    if (
      this.item.audit != null &&
      this.item.audit >= 0 &&
      this.item.auditStatus === this.item.audit
    ) {
      return true;
    } else {
      return false;
    }
  }
}
