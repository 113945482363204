





























































































































import {Component, Vue, Ref} from "vue-property-decorator";
import api from "@/api";
import {
  BbsPostDto,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  FundAccountDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  components: {
    ExportButton,
    PagedTableView,
    AbSelect,
  },
})
export default class BbsPost extends Vue {
  editId = 0;
  queryForm = {
    groupName: "",
    groupType: "",
    realName: "",
    createTime: undefined,
  };

  groupTypeList: DataDictionaryDto[] = [];

  created() {
    this.fetchData(this.queryForm);
    this.fetchGroupTypeDataDictionary();
  }

  fetchData(params: any) {
    return api.bbsPost.getAll(params);
  }

  jumpDetail(index: number, row: BbsPostDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "bbsPostDetail",
      params: {id: row.id!.toString()},
    });
  }

  fetchGroupTypeDataDictionary() {
    api.dataDictionary
      .getDataDictionaryListByKey({
        key: "BbsGroupType",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.groupTypeList = res!.items!;
      });
  }

  handleDelete(index: number, row: BbsPostDto) {
    this.$confirm("您确认要主贴吗？", "提示").then(() => {
      api.bbsPost.delete({id: row.id}).then(() => {
        this.$message.success("删除成功!");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleStickyPost(index: number, row: BbsPostDto) {
    this.$confirm("您确定要置顶吗？", "提示").then(() => {
      api.bbsPost.stickyPost({body: {id: row.id}}).then(() => {
        this.$message.success("置顶成功!");
        this.fetchData(this.queryForm);
        (this as any).$bus.$emit("refresh-data");
      });
    });
  }

  handleNotStickyPost(index: number, row: BbsPostDto) {
    this.$confirm("您确定要取消置顶吗？", "提示").then(() => {
      api.bbsPost.stickyPost({body: {id: row.id}}).then(() => {
        this.$message.success("取消成功!");
        (this as any).$bus.$emit("refresh-data");
        this.fetchData(this.queryForm);
      });
    });
  }

  jumpToBbsPostComment(row: BbsPostDto) {
    this.$router.push({
      name: "bbsPostComment",
      query: {postId: row.id!.toString()},
    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
