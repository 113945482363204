








































































































import api from "@/api";
import { Component, Vue, Ref } from "vue-property-decorator";
import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  BbsGroupUserDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";

@Component({
  components: { PagedTableView },
})
export default class BbsGroupUserList extends Vue {
  @Ref() readonly pagedTableView!: any;
  dataId = 0;
  queryForm = {
    name: "",
    volunteerOrgCode: undefined,
    phoneNumber: undefined,
    bbsGroupId: 0,
  };
  BbsGroupTypeList: DataDictionaryDto[] = [];

  fetchData(params: any) {
    // debugger;
    return api.bbsGroupUser.getAll({
      name: this.queryForm.name,
      volunteerOrgCode: this.queryForm.volunteerOrgCode,
      phoneNumber: this.queryForm.phoneNumber,
      bbsGroupId: this.dataId,
    });
  }

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
    }

    this.fetchServiceOrganizationTypeList();
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.bbsGroup.get({ id: this.dataId }).then((res) => {
        this.form = { ...res };
      });
    }
  }

  async fetchServiceOrganizationTypeList() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ServiceOrganizationType",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.BbsGroupTypeList = res!.items!;
      });
  }

  // 跳转详情页
  handleDetail(index: number, row: BbsGroupUserDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "VolunteerDetail",
      params: { id: row.volunteerId!.toString() },
    });
  }

  //禁用群组成员
  handleDisable(index: number, row: BbsGroupUserDto) {
    this.$confirm("您确定禁用群组成员吗?", "提示").then(() => {
      api.bbsGroupUser.disable({ id: row.id }).then((res) => {
        this.$message.success("禁用群组成员成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  //启用群组成员
  handleEnable(index: number, row: BbsGroupUserDto) {
    this.$confirm("您确定启用群组成员吗?", "提示").then(() => {
      api.bbsGroupUser.enable({ id: row.id }).then((res) => {
        this.$message.success("启用群组成员成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
