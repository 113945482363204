import { ActionContext } from "vuex";

export interface PagedViewCache {
  url: string;
  queryCondition: any;
  page: number;
  pageSize: number;
}


export interface PagedViewState {
  caches: PagedViewCache[];
}


export default {
  namespaced: true,
  state: () => ({
    caches: []
  } as PagedViewState),
  mutations: {
    SAVE_CACHE(state: PagedViewState, payload: PagedViewCache) {
      const lastIndex = state.caches.length ? state.caches.length - 1 : -1;
      if (lastIndex >= 0 && state.caches[lastIndex].url === payload.url) {
        state.caches[lastIndex] = payload;
      } else {
        state.caches.push(payload);
      }
    },
    POP_LAST(state:PagedViewState){
      state.caches.pop();
      console.log('pagedView/popLast called.')
    },

    CLEAR(state: PagedViewState) {
      state.caches = [];
      console.log('pagedView/clear called.')
    }
  },
  actions: {
    getLastCache(context: ActionContext<PagedViewState, any>): Promise<PagedViewCache> {
      return new Promise<PagedViewCache>((resolve) => {
        let cache:PagedViewCache
        if(context.state.caches.length>0){
          cache = context.state.caches[context.state.caches.length -1]
          context.commit('POP_LAST')
        } else {
          cache = {pageSize:10,page:1,queryCondition:{},url:''}
        }
        resolve(cache);
      });
    },
  }
};
