










































import api from "@/api"; //ABP API接口
import {Vue, Component, Ref} from "vue-property-decorator";
import ImportExcel from "@/components/ImportExcel/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

import {
  CustomFormType,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import ExportButton from "@/components/ExportButton/index.vue";
import {regionData, CodeToText} from "element-china-area-data";

Vue.use(regionData, CodeToText);
@Component({
  name: "ServiceList",
  components: {
    PagedTableView,
    AbSelect,
    ImportExcel,
    AgileAuditTag,
    ExportButton
  }
})
export default class ServiceList extends Vue {
  @Ref() readonly pagedTableView!: any;

  queryForm = {
  };

  // 获取表数据
  async fetchData(params: any) {
    params.serviceId = this.$route.params.serviceId;
    return await api.serviceObjectFeedback.getAll(params);
  }


  // 查看详情
  jumpDetail(index: number, row: any) {
    this.getCustomFormId( (formId: string) => {
      this.$router.push({
        name: "serviceObjectFeedbackDetail",
        query: {id: row.id!.toString(), formId: formId}
      });
    });
  }


  async getCustomFormId( callBack: any) {
    await api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.ServiceObjectFeedback,
      })
      .then((res) => {
        callBack(res);
      });
  }

}
