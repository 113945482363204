





















































































































import {Component, Ref, Vue} from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  HelpAppliesDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "AuditTabOfHelpApply",
  components: {
    AuditTabPane,
    AgileAuditTag,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class AuditTabOfHelpApply extends Vue {
  @Ref() readonly auditTabPane!: any;
  tableItems: HelpAppliesDto[] = [];
  typeList: any = [];
  visible = false;
  rowId = undefined;

  queryForm: any = {
    name: "",
  };

  unitList: any = [];
  // 获取表数据
  get fetchData() {
    return api.helpApplies.getMyAll;
  }


  handleAudit(dto: any) {
    return this.$router.push({
      name: "helpAppliesDetail",
      params: {
        id: dto.id,
      },
    });
  }
  showAllocationOrg = false;
  async created() {
    api.session.getCurrentLoginInformations().then((res)=>{
      if (res&&res.user&&res.user.userName==="admin"){
        this.showAllocationOrg=true;
      }
    })
    api.organizationUnit.getAllApproved().then((res) => {
      this.unitList = res;
    });
  }
  orgId = "";

  handleUpdateDistributionOrg() {
    if (this.orgId) {
      this.$confirm("确定要分配给该机构审核吗?", "提示").then(async () => {
        await api.helpApplies.distributionAndUpdateOrg({
          body: {
            tobeOrganizationUnitId: parseInt(this.orgId),
            id: this.rowId
          }
        }).then((res) => {
          this.visible = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.auditTabPane!.fetchData();

        });
      });
    } else {
      this.$message({
        type: "error",
        message: "请选择机构!",
      });

    }

  }

  handleShowLog(row: any) {
    this.visible = true;
    this.rowId = row.id;
    console.log(this.rowId)
  }

  handleDistributionOrg(row: HelpAppliesDto) {
    this.$confirm("确定要分配给该机构审核吗?", "提示").then(async () => {
      this.visible = true;
    });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.HelpApply,
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
