


































import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {
  CmsFriendLinkDto,
  CmsFriendLinkCreateOrUpdateDto,
  AttachmentHostType, TrainApplyCreateOrUpdateDto, ServiceApplyCheckInRecordDto, ServiceApplyCheckInPostDto, UserDto
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import ElOptionSpan from "@/components/AbSelect/elOptionSpan.vue";

@Component({
  name: "EditFriendLink",
  components: {ElOptionSpan},
})
export default class EditFriendLink extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  trainId!: number;
  loading = false;

  defaultData: TrainApplyCreateOrUpdateDto = {
    trainId: undefined,
    userId: undefined,
  };

  show = false;
  form: TrainApplyCreateOrUpdateDto = { ...this.defaultData };
  userList: UserDto[] = [];



  get title() {
    return `选择要报名的人员`;
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      await this.getUserList();
      const loading = this.$loading({
        target: ".el-dialog",
      });

      this.form = { ...this.defaultData };
      //
      setTimeout(() => {
        loading.close();
      }, 200);
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }
  async getUserList() {
    this.loading = true;
    await api.user.getAll({maxResultCount: 65535, mode: "foundation",isOnlyMembership:true}).then((res) => {
      this.userList = res.items!;
      this.loading = false;
    });
  }
  async save() {
    console.log(this.form);
    console.log(this.form2);

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.trainApply.createByOp({body:{trainId:this.trainId,userId:this.form.userId}})
        this.show = false;
        this.$message.success("报名成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    userId: [
      {
        required: true,
        message: "请选择人员",
        trigger: "blur",
      },
    ],
  };
}
