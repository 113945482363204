



















































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component, Ref} from "vue-property-decorator";
import {
  AuditUserLogStatus, UserAuditInput,
  VolunteerDto,
  VolunteerPagedResultRequestDto,
  VolunteerStatus,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import RejectDialog from "@/components/RejectDialog/index.vue";
import {forEach} from "node_modules/@types/lodash";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "ActivityList",
  components: {
    ExportButton,
    RejectDialog,
    PagedTableView,
  },
})
export default class VolunteerList extends Vue {
  @Ref() tableView!: PagedTableView;
  @Ref() backDialog!: RejectDialog;
  @Ref() backDialogBatch!: RejectDialog;
  queryForm: VolunteerPagedResultRequestDto = {
    volunteerName: "",
    volunteerPhone: "",
    volunteerCode: "",
    status: undefined,
    includeUserExtend: true,
  };

  id = 0;

  statusList: any[] = [];

  created() {
    api.enumService
      .getValues({typeName: "VolunteerStatus"})
      .then((res: any) => {
        this.statusList = res.filter((x: any) => x.text !== "停用");
      });
  }

  get VolunteerStatus() {
    return VolunteerStatus;
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.volunteer.getAll(params);
  }

  handleDetail(index: number, row: VolunteerDto) {
    this.$router.push({
      name: "VolunteerAuditDetail",
      params: {id: `${row.id!}`},
    });
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }

  body: UserAuditInput = {
    hostId: undefined,
    status: undefined,
    desc: "审核通过",
  };

  handleAproved(index: number, row: VolunteerDto): void {
    this.body.hostId = row.id + "";
    this.body.status = AuditUserLogStatus.Pass;
    this.body.desc = "审核通过";

    api.volunteer.audit({body: this.body}).then(() => {
      this.$message({
        type: "success",
        message: "操作成功!",
      });
      this.fetchData(this.queryForm);
    });
  }

  handleReject(index: number, row: VolunteerDto) {
    this.body.hostId = row.id + "";
    this.body.status = AuditUserLogStatus.Reject;
    (this.backDialog as any).show = true;
  }

  backed(text: string) {
    this.body.desc = text;
    console.log(text);
    api.volunteer.audit({body: this.body}).then(() => {
      this.$message({
        type: "success",
        message: "操作成功!",
      });
      this.fetchData(this.queryForm);
    });
  }

  selection: UserAuditInput[] = [];

  handleBatchAudited() {
    let postData = this.selection.map((x) => {
      x.desc = "批量审核通过";
      x.status = AuditUserLogStatus.Pass;
      return x;
    });
    console.log(postData);
    api.volunteer.batchAudit({body: {list: postData}}).then(() => {
      this.$message({
        type: "success",
        message: "操作成功!",
      });
      this.fetchData(this.queryForm);
    });
  }

  handleBatchReject() {
    (this.backDialogBatch as any).show = true;
  }

  backedBatch(text: string) {
    let postData = this.selection.map((x) => {
      x.desc = text;
      x.status = AuditUserLogStatus.Reject;
      return x;
    });
    console.log(postData);
    api.volunteer.batchAudit({body: {list: postData}}).then(() => {
      this.$message({
        type: "success",
        message: "操作成功!",
      });
      this.fetchData(this.queryForm);
    });
  }

  handleSelectionChange(e: VolunteerDto[]) {
    //todo:不同auditflowid的应该是不能一起选中
    if (e.length > 0) {
      this.selection = e.map((x: VolunteerDto) => {
        return {
          hostId: x.id + "",
          auditStatus: x.auditStatus,
          auditFlowId: x.auditFlowId,
        };
      });
      console.log("selection is :", this.selection);
    }
  }

  selectable(row: any, index: any) {
    return row.status === VolunteerStatus.Pending;
  }
}
