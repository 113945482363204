
























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import {AttachmentHostType, BbsGroupDto, ServiceApplyCheckInRecordDto} from "@/api/appService";
import AttachmentsView from "@/components/AttachmentsView/index.vue";


@Component({
  name: "UnselectedList",
  components: {
    AttachmentsView,
    PagedTableView,
    AbSelect,
    ExportButton
  },
})
export default class UnselectedList extends Vue {
  @Prop() trainId?: number;


  queryForm = {
    surName: "",
    phone: undefined,
    status:undefined,
    serviceId: 0
  };
  visible = false;
  hostType = AttachmentHostType.ServiceCheckIn;

  created() {
    this.queryForm.serviceId = this.trainId!;
  }

  fetchData(params: any) {
    return api.serviceApply.getServiceApplyCheckInRecord({
      ...params,
      surName: this.queryForm.surName,
      phone: this.queryForm.phone,
      serviceId: this.trainId,
    });
  }


  // 删除
  handleAuditPass( row: ServiceApplyCheckInRecordDto) {
    this.$confirm("您确定审核通过该签到吗?", "提示").then(() => {
      api.serviceApply.checkInAudit({ body:{id:row.id,isAudit:true,auditReason:""}}).then((res) => {
        this.$message.success("审核成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  rowId=undefined;
  auditReason="";
  handleAuditReject(row: any){
    this.visible=true;
    this.rowId=row.id;
    this.auditReason="";
  }

  handleAuditRejectSubmit( ) {
    if (!this.auditReason){
      this.$message.error("请输入拒绝原因");
      return
    }
      api.serviceApply.checkInAudit({ body:{id:this.rowId,isAudit:false,auditReason:this.auditReason}}).then((res) => {
        this.$message.success("审核成功");
        this.visible=false
        this.fetchData(this.queryForm);
      });
  }
}
