



















































































































































































































































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {ElForm} from "element-ui/types/form";
import {
  AttachmentHostType,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  LecturerDto,
  LecturerDtoPagedResultDto,
  TrainChapterDto,
  TrainCreateOrUpdateDto,
  TrainMethod,
  TrainStatus,
  VolunteerDto,
} from "@/api/appService";
import api from "@/api";
import multipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleVideoUpload from "@/components/SimpleVideoUpload/index.vue";
import SingleFileUploadOSS from "@/components/SingleFileUploadOSS/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";

import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import {CodeToText, regionData} from "element-china-area-data";
import moment from "moment";
import ElOptionSpan from "@/components/AbSelect/elOptionSpan.vue";

Vue.use(regionData, CodeToText)

@Component({
  components: {
    SimpleVideoUpload,
    SimpleUploadImage,
    "single-file-upload-oss": SingleFileUploadOSS,
    multipleUploadFile,
    Ueditor,
    BaiduMap,
    ElOptionSpan
  }
})

export default class EditCmsContent extends Vue {
  @Ref() dataForm!: ElForm;
  private activityDate: any = [];
  // courseTypeList = getCourseTypeList();
  courseId?: number = 0;
  submitting = false;
  selectedOptions: any[] = ["", "", ""];
  options: any = regionData;
  isFree = true;
  isActivityEnd = true;
  trainTypeArray: DataDictionaryDto[] = [];
  type: any = {}
  categoryArray: any = [];
  allTags: any = [];
  tagInputValue = "";
  tagInputVisible = false;

  lecturerList: LecturerDto[] = [];
  volunteerList: VolunteerDto[] = [];
  volunteerLevelList: DataDictionaryDto[] = [];
  trainMethodArray: any = [{name: '线上', value: TrainMethod.Online}, {name: '线下', value: TrainMethod.Offline}];
  YesOrNo: any = [{name: '是', value: true}, {name: '否', value: false}];
  applyTimeTypes: any[] = [
    {label: "默认培养开始时间", value: 0},
    {label: "自定义", value: 1},
  ];


  form: TrainCreateOrUpdateDto = {
    id: 0,
    titleImagePath: undefined,
    title: undefined,
    trainMethod: TrainMethod.Online,
    trainTag: "",
    content: undefined,
    trainTypeId: undefined,
    trainStartTime: undefined,
    trainEndTime: undefined,
    point: 0,
    price: 0,
    host: undefined,
    needCompleteCheckInTimesWhenExam: undefined,
    lecturerId: undefined,
    placeCoordinate: "",
    provinceId: undefined,
    cityId: undefined,
    areaId: undefined,
    address: undefined,
    readCount: 0,
    signUpCount: 0,
    thumbsUpCount: 0,
    applyEndTime: undefined,
    minLevel: undefined,
    minPoint: 0,
    aimAmount: 0,
    needApprove: true,
    status: TrainStatus.Saved,
    trainChapterList: [],
    applyTimeType: 0
  };
  activeNames: any[] = ['1', '2']
  zoom = 13;
  BMap: any;
  map: any;
  address_detail = "";
  provincecityarea = "";
  userlocation: any = {
    lng: undefined,
    lat: undefined
  };

  oldApplyEndTime: any;
  lastApplyEndTime: any;
  oldEndTime: any;
  lastEndTime: any;

  get hostTypeFile() {
    return AttachmentHostType.Train;
  }

  get isOnlineTrain() {
    return this.form.trainMethod == TrainMethod.Online;
  }

  handleCreateItem() {
    this.createItem({});
  }

  HandleDeleteItem(index: any) {
    this.form.trainChapterList!.splice(index, 1)
  }

  createItem(item: TrainChapterDto) {
    this.form.trainChapterList!.push(item);
  }


  changeMap() {
    //this.address_detail += val
    if (this.form.trainMethod === "Offline") {
      this.address_detail = this.provincecityarea + this.form.address;
      this.setPlace();
    }
  }

  async created() {
    //
    await api.dataDictionary.getDataDictionaryListByKey({
      maxResultCount: 65535,
      key: 'TrainType'
    }).then((res: DataDictionaryDtoPagedResultDto) => {
      this.trainTypeArray = res.items!;
    });
    //
    await api.dataDictionary.getDataDictionaryListByKey({
      maxResultCount: 65535,
      key: 'VolunteerLevel'
    }).then((res: DataDictionaryDtoPagedResultDto) => {
      this.volunteerLevelList = res.items!;
    });

    await api.lecturer.getAll({
      maxResultCount: 65535
    }).then((res: LecturerDtoPagedResultDto) => {
      this.lecturerList = res.items!;
      //this.tags = res.item.select(m => m.displayName);
    });

    await api.volunteer.getAll({maxResultCount: 65535}).then(res => {
      this.volunteerList = res.items!;
    })

    //
    if (this.$route.params.id) {
      this.courseId = Number(this.$route.params.id);
      await this.fetchDetail();
    } else {
      await this.getAllTags();
    }

  }

  // 获取详情
  async fetchDetail() {
    await api.train.getForEdit({id: this.courseId}).then(res => {
      this.form = {...res};
      this.form.applyTimeType = 1;
      this.$set(this.form,'applyEndTime', moment(res.applyEndTime).format('YYYY-MM-DD HH:mm:ss'));
      this.$set(this.form,'trainExamStartTime', moment(res.trainExamStartTime).format('YYYY-MM-DD HH:mm:ss'));
      this.$set(this.form,'trainExamEndTime', moment(res.trainExamEndTime).format('YYYY-MM-DD HH:mm:ss'));
      this.$set(this.form,'checkInStartTime', moment(res.checkInStartTime).format('YYYY-MM-DD HH:mm:ss'));
      this.allTags = res.tagList!;
      this.oldApplyEndTime = res.applyEndTime!;
      this.oldEndTime = res.trainEndTime!;
      this.selectedOptions = [
        res!.provinceId + "",
        res!.cityId + "",
        res!.areaId + "",
      ];
      let _this = this as any;
      if (this.form.trainMethod === TrainMethod.Offline) {
        setTimeout(function () {
          _this.address_detail = _this.provincecityarea + _this.form.address;
          _this.setPlace();
        }, 1000);

      }
    })
  }

  checkTime(time:any) {
    if((new Date(time)).getTime() < (new Date(this.oldApplyEndTime)).getTime() && this.oldApplyEndTime) {
      this.$message.error('报名截止时间只能增加，不能减少！')
      this.form.applyEndTime = this.lastApplyEndTime?this.lastApplyEndTime:moment(this.oldApplyEndTime).format('YYYY-MM-DD HH:mm:ss')
    } else if((new Date(time)).getTime() > new Date(this.form.trainEndTime!).getTime()){
      this.$message.error('报名截止时间不能晚于服务结束时间！')
      this.form.applyEndTime = this.lastApplyEndTime?this.lastApplyEndTime:moment(this.oldApplyEndTime).format('YYYY-MM-DD HH:mm:ss')
    }else {
      this.form.applyEndTime = time
      this.lastApplyEndTime = time
    }
  }

  async getAllTags() {
    await api.dataDictionary.getDataDictionaryListByKey({
      key: 'TrainTag'
    }).then((res: any) => {
      //this.tags = res.item.select(m => m.displayName);
      let tags = res.items!;
      tags.map((m: any) => {
        this.allTags.push({tag: m.displayName, isChosen: false});
      })
    });
  }

  // 折叠面板Collapse 事件
  handleCollapseChange(val: any) {
    console.log(val, 'Collapse')
  }

  showInput() {
    this.tagInputVisible = true;
    this.$nextTick(() => {
      //input自动获取焦点
      (this.$refs.saveTagInput as any).$refs.input.focus();
    });
  }

  handleTagInputConfirm() {
    let inputValue = this.tagInputValue;
    if (inputValue) {
      //分别校验allTags是否已存在新增tag
      let index = this.allTags.map((m: any) => m.tag).indexOf(inputValue);
      console.log("index" + index);
      if (index < 0) {
        this.allTags.push({tag: inputValue, isChosen: true});
      } else {
        this.allTags[index].isChosen = !this.allTags[index].isChosen;
      }
    }
    this.tagInputVisible = false;
    this.tagInputValue = '';
  }

  handleTagClick(index: any) {
    this.allTags[index].isChosen = !this.allTags[index].isChosen;
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  //   省市区
  handleChange(value: any, label: any) {
    this.form!.provinceId = value[0];
    this.form!.cityId = value[1];
    this.form!.areaId = value[2];
    this.address_detail =
      CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]];
    this.provincecityarea =
      CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]];
    this.setPlace();
  }

  map_handler(result: any) {
    this.BMap = result.BMap;
    this.map = result.map;
  }

  //地址
  setPlace() {
    let th = this as any;

    if (this.map) {
      this.map.clearOverlays(); //清除地图上所有覆盖物

      let local = new th.BMap.LocalSearch(th.map, {
        //智能搜索
        onSearchComplete: function () {
          if (local && local.getResults().getPoi(0)) {
            console.log(local.getResults().getPoi(0));
            th.userlocation = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
            th.map.centerAndZoom(th.userlocation, 18);
            th.map.addOverlay(new th.BMap.Marker(th.userlocation)); //添加标注
            th.form!.placeCoordinate =
              "" + th.userlocation.lng + "," + th.userlocation.lat;
          }
        },
      });
      local.search(th.address_detail);
      console.log(th.address_detail);
      th.map.addEventListener("click", function (e: any) {
        th.form!.placeCoordinate =
          "" + th.userlocation.lng + "," + th.userlocation.lat;
      });
    }
  }

  //
  back() {
    this.$router.go(-1)
  }

  private async save(isPublish: boolean) {
    console.log(this.form);
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (!this.form.content) {
          this.$message({
            type: 'error',
            message: '请填写培养内容'
          })
          return
        }

        if (this.form.applyTimeType == 0) {
          this.form.applyEndTime = this.form.trainStartTime
        }
        if (this.form.trainMethod == TrainMethod.Online) {
          this.form.lecturerUserId = undefined;
        }
        if (this.form.trainMethod == TrainMethod.Offline) {
          this.form.lecturerId = undefined;
        }

        if (isPublish) {
          this.form.status = TrainStatus.Published;
        } else {
          this.form.status = TrainStatus.Saved;
        }

        (this as any).$bus.$emit('before-save');
        this.submitting = true
        let fn = undefined
        if (this.form!.id) {
          fn = api.train.update
        } else {
          fn = api.train.create
        }
        this.form.trainTag = this.allTags.filter((m: any) => m.isChosen).map((m: any) => m.tag).join(',') + ',';
        await fn({body: this.form}).then(res => {
          this.$message({
            type: "success",
            message: "操作成功"
          });
          setTimeout(() => {
            this.$router.push({name: "trainList"});
          }, 200);
        }).catch(() => {
          this.submitting = false
        });
      } else {
        this.$message({
          type: 'error',
          message: '请检查表单'
        })
      }
    });
  }

  get dd() {
    return [this.form!.trainStartTime, this.form!.trainEndTime];
  }

  set dd(e) {
    if (e && e.length === 2) {
      if(new Date(e[1]!).getTime() < new Date(this.oldEndTime).getTime()) {
        this.$message.error('服务结束时间只能增加，不能减少！')
        this.form.trainEndTime = this.lastEndTime?this.lastEndTime:moment(this.oldEndTime).format('YYYY-MM-DD HH:mm:ss')
      } else {
        console.log(e);
        (this.form as any)!.trainStartTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        (this.form as any)!.trainEndTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
        this.lastEndTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
        this.$forceUpdate()
      }
    }
  }

  get t1() {
    return moment(this.form!.trainStartTime).format("HH:mm");
  }

  set t1(e) {
    if (e) {
      let _t = e.split(":");
      (this.form as any)!.trainStartTime = moment(this.form!.trainStartTime)
        .hour(parseInt(_t[0]))
        .minute(parseInt(_t[1]))
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(this.form!.trainStartTime);
    }
  }

  get t2() {
    return moment(this.form!.trainEndTime).format("HH:mm");
  }

  set t2(e) {
    if (e) {
      let _t = e.split(":");
      (this.form as any)!.trainEndTime = moment(this.form!.trainEndTime)
        .hour(parseInt(_t[0]))
        .minute(parseInt(_t[1]))
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(this.form!.trainEndTime);
    }
  }

  roleRule = {
    title: [{
      required: true,
      message: '请填写培养主题',
      trigger: 'blur'
    }],
    content: [
      {
        required: true,
        message: "请填写内容",
        trigger: "blur"
      }
    ],
    trainTypeId: [
      {
        required: true,
        message: "请填写培养类型",
        trigger: "blur"
      }
    ],

    lecturerId: [
      {
        required: true,
        message: "请选择培养讲师",
        trigger: "blur"
      }
    ],
    lecturerUserId: [{
      required: true,
      message: "请选择培养讲师",
      trigger: "blur"
    }],


    titleImagePath: [
      {
        required: true,
        message: '请上传标题图片',
        trigger: 'blur'
      }
    ],
    address: [{
      required: true,
      message: '请填写培养地点',
      trigger: 'blur'
    }],
    areaId: [{
      required: true,
      message: '请填写培养详细地址',
      trigger: 'blur'
    }],
    point: [{
      required: true,
      message: '请填写单次积分',
      trigger: 'blur'
    }],

    trainEndTime: [{
      required: true,
      message: '请填写培养时间',
      trigger: 'blur'
    }],
    trainExamStartTime: [{
      required: true,
      message: '请填写培训考试开始时间',
      trigger: 'blur'
    }],
    trainExamEndTime: [{
      required: true,
      message: '请填写培训考试结束时间',
      trigger: 'blur'
    }],
    checkInStartTime: [{
      required: true,
      message: '请填写签到开始时间',
      trigger: 'blur'
    }],

    price: [{
      required: true,
      message: '请填写门票金额',
      trigger: 'blur'
    }],
    name: [{
      required: true,
      message: '请填写章节',
      trigger: 'blur'
    }],
    path: [{
      required: true,
      message: '请上传附件',
      trigger: 'blur'
    }],
    // duration: [{
    //   required: true,
    //   message: '请填写时长',
    //   trigger: 'blur'
    // }]
  };
}
