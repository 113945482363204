







import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import { ProvinceDto, CityDto, AreaDto, TownDto } from "@/api/appService";

export interface ProvinceCityAreaTownDto {
  /**  */
  provinceId?: undefined;

  /**  */
  cityId?: undefined;

  /**  */
  areaId?: undefined;

  townId?: undefined;
}

@Component({
  name: "ProvinceCityAreaContent",
})
export default class ProvinceCityAreaContent extends Vue {
  @Prop({ required: false, default: {} })
  hostData!: ProvinceCityAreaTownDto;

  provinceList: ProvinceDto[] = [];
  cityList: CityDto[] = [];
  areaList: AreaDto[] = [];
  townList: TownDto[] = [];

  province = "";
  city = "";
  area = "";
  town = "";

  created() {
    this.updateValue();
  }

  @Watch("hostData", { deep: true })
  onHostDataChanged() {
    this.updateValue();
  }

  updateValue() {
    this.fetchProvince().then((res) => {
      const filterResult = res.filter(
        (item) => item.id === this.hostData.provinceId
      );
      if (filterResult.length) {
        this.province = filterResult[0].provinceName!;
      }
    });

    this.fetchCity().then((res) => {
      const filterResult = res.filter(
        (item) => item.id === this.hostData.cityId
      );
      if (filterResult.length) {
        this.city = filterResult[0].cityName!;
      }
    });

    this.fetchArea().then((res) => {
      const filterResult = res.filter(
        (item) => item.id === this.hostData.areaId
      );
      if (filterResult.length) {
        this.area = filterResult[0].areaName!;
      }
    });

    this.fetchTown().then((res) => {
      const filterResult = res.filter(
        (item) => item.id === this.hostData.townId
      );
      if (filterResult.length) {
        this.town = filterResult[0].townName!;
      }
    });
  }

  fetchProvince(): Promise<ProvinceDto[]> {
    return new Promise<ProvinceDto[]>((resolve) => {
      if (this.provinceList.length) {
        resolve(this.provinceList);
      } else if (this.hostData.provinceId) {
        api.province.getAllList().then((res) => {
          this.provinceList = res;
          resolve(res);
        });
      } else {
        resolve([]);
      }
    });
  }

  fetchCity(): Promise<CityDto[]> {
    return new Promise<CityDto[]>((resolve) => {
      if (this.cityList.length) {
        resolve(this.cityList);
      } else if (this.hostData.provinceId && this.hostData.cityId) {
        api.city
          .getAllList({ provinceId: this.hostData.provinceId })
          .then((res) => {
            this.cityList = res;
            resolve(res);
          });
      } else {
        resolve([]);
      }
    });
  }

  fetchArea(): Promise<AreaDto[]> {
    return new Promise<AreaDto[]>((resolve) => {
      if (this.areaList.length) {
        resolve(this.areaList);
      } else if (this.hostData.areaId && this.hostData.cityId) {
        api.area.getAllList({ cityId: this.hostData.cityId }).then((res) => {
          this.areaList = res;
          resolve(res);
        });
      } else {
        resolve([]);
      }
    });
  }

  fetchTown(): Promise<TownDto[]> {
    return new Promise<TownDto[]>((resolve) => {
      if (this.townList.length) {
        resolve(this.townList);
      } else if (this.hostData.areaId && this.hostData.townId) {
        api.town.getAllList({ areaId: this.hostData.areaId }).then((res) => {
          this.townList = res;
          resolve(res);
        });
      } else {
        resolve([]);
      }
    });
  }
}
