












































































import api from "@/api"; //ABP API接口
import {Component, Vue} from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";
import { DataDictionaryDtoPagedResultDto, DataDictionaryDto, StarRatingService } from "@/api/appService";
import AbSelect from "@/components/AbSelect/index.vue";
import { getStarRatingStatusList, getTrainStatusList } from "@/utils/enumToSelectItem";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "StarRatingList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    ExportButton
  }
})
export default class StarRatingList extends Vue {
  statusList = getStarRatingStatusList();
  typeList:DataDictionaryDto[]=[];
  queryForm: any = {
    title: undefined,
    typeId:undefined,
    creationTime:undefined,
  }

  created(){
    this.fetchDataDictionary()
  }

  // 获取表数据
  async fetchData(params: any) {
    return await api.starRating.getAll(params);
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "EvaluationType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items!;
      });
  }

  //发布新的星级评定
  handleCreate() {
    this.$router.push({name: 'star-level-evaluate-publish'})
  }


  // 跳转详情页
  handleDetail(item: any) {
    this.$router.push({name: 'maintain-detail', params: {'id': item.id!.toString()}})
  }
  // 考题管理
  handleExamList(item: any) {
    this.$router.push({name: 'examination', params: {'id': item.id!.toString()}})
  }

  // 报名管理
  handleRegistration(item: any) {
    this.$router.push({name: 'registration', params: {'id': item.id!.toString()}})
  }

  // 跳转编辑页
  handleEdit(item: any) {
    this.$router.push({name: 'star-level-evaluate-edit', params: {'id': item.id!.toString()}})
  }

  //删除
  handleDelete(item: any) {
    this.$confirm('确定删除吗?', '提示').then(() => {
      api.starRating.delete({id: item.id}).then(res => {
        this.$message.success('删除成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }

  //发布
  handlePublish(item: any) {
    this.$confirm('确定发布吗?', '提示').then(() => {
      api.starRating.publish({body: {id:item.id}}).then(res => {
        this.$message.success('发布成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }
  // 取消发布
  handleCancelPublish(item: any) {
    let confirmMessage='确定取消发布吗?';
    if((item.signUpCount??0)>0){
      confirmMessage='确定取消发布吗?当前培养已有用户报名，取消培养会造成报名用户无法继续培养，请谨慎操作';
    }
    this.$confirm(confirmMessage, '提示').then(() => {
      api.starRating.unPublish({body:{id: item.id}}).then(res => {
        this.$message.success('取消发布成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }
}
