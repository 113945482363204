







































































































import api from "@/api"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  ServiceOrganizationDto,
  AuditFlowScope, SelectListItem, UserAuditInput, HelpAppliesDto, AuditUserLogStatus
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import moment from "moment";
import ExportButton from "@/components/ExportButton/index.vue";
import RejectDialog from "@/components/RejectDialog/index.vue";
@Component({
  name: "RecordList",
  components: {
    PagedTableView,
    AgileAuditTag,
    RejectDialog,
    ExportButton
  },
})
export default class ServiceRecordList extends Vue {
  @Ref() readonly pagedTableView!: any;

  queryForm: any = {
    name: undefined,
    phone: undefined,
   startTime: undefined,
    endTime: undefined,
  };

  typeList: SelectListItem[] = [];



  // 获取表数据
  async fetchData(params: any) {
    return await api.userSummaryOfOneHundredDay.getAll(params);
  }

}
