









































































































































































































































































































































import {Component, Vue, Prop, Watch, Ref} from "vue-property-decorator";
import Ueditor from "@/components/Ueditor/index.vue";
import {ElForm} from "element-ui/types/form";
import moment from "moment";
import api from "@/api";
import {
  ActivityCreateOrUpdateDto, ActivitySignUpFormSchemaDto,
  AttachmentHostType,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto, UserDto
} from "@/api/appService";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import {regionData, CodeToText} from "element-china-area-data";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import ElOptionSpan from "@/components/AbSelect/elOptionSpan.vue";
import draggable from "vuedraggable";

Vue.use(regionData, CodeToText);
@Component({
  components: {SimpleUploadImage, Ueditor, BaiduMap, ElOptionSpan, draggable}
})
export default class EditActivity extends Vue {
  @Ref() readonly dataForm!: ElForm;
  selectedOptions: any[] = ["", "", ""];
  options: any = regionData;
  address_detail = "";
  userlocation: any = {
    lng: undefined,
    lat: undefined
  };
  serviceTime: any = 0;
  zoom = 13;
  BMap: any;
  map: any;
  provincecityarea = "";
  btnDisabled = false;
  volunteerLevelList: DataDictionaryDto[] = [];
  dataId = 0;
  defaultData: ActivityCreateOrUpdateDto = {
    id: 0,
    activityStartTime: undefined,
    activityEndTime: undefined,
    applyEndTime: undefined,

  };
  activityTypes: any[] = [
    {label: "普通活动", value: false},
    {label: "志愿者活动", value: true}
  ];
  activityTypeDictionaries: DataDictionaryDto[] = [];
  applyTimeTypes: any[] = [
    {label: "默认活动截止时间", value: 0},
    {label: "自定义", value: 1}
  ];
  leaderList: UserDto[] = [];
  show = false;
  form: any = {...this.defaultData};
  roleRule = {
    title: [
      {
        required: true,
        message: "活动标题 必填",
        trigger: "blur"
      }
    ],
    leaderUserId: [
      {
        required: true,
        message: "领队 必选",
        trigger: "blur"
      }
    ],
    titleImagePath: [
      {
        required: true,
        message: "活动封面图片 必须上传",
        trigger: "blur"
      }
    ],
    content: [
      {
        required: true,
        message: "活动内容 必填",
        trigger: "blur"
      }
    ],
    // typeID: [
    //   {
    //     required: true,
    //     message: "活动类型 必选",
    //     trigger: "blur",
    //   },
    // ],
    areaId: [
      {
        required: true,
        message: "活动地区 必填",
        trigger: "blur"
      }
    ],
    place: [
      {
        required: true,
        message: "详细地址 必填",
        trigger: "blur"
      }
    ],
    totalHours: [
      {
        required: true,
        message: "请正确选择活动时间",
        trigger: "blur"
      }
    ],
    aimAmount: [
      {
        required: true,
        message: "限制报名人数 必填",
        trigger: "blur"
      }
    ],
    point: [
      {
        required: true,
        message: "单次积分必填",
        trigger: "blur"
      }
    ]
  };

  oldApplyEndTime: any;
  lastApplyEndTime: any;
  oldEndTime: any;
  lastEndTime: any;

  private activityDate: any = [];
  // private dd: any = [];
  // private t1: any = [];
  // private t2: any = [];

  get dialogTitle() {
    return this.form!.id ? `编辑 ${this.form.title}` : "新建";
  }

  get hostType() {
    return AttachmentHostType.Activity;
  }

  hasActivityPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }

  checkTime(time: any) {
    if ((new Date(time)).getTime() < (new Date(this.oldApplyEndTime)).getTime() && this.oldApplyEndTime) {
      this.$message.error('报名截止时间只能增加，不能减少！')
      this.form.applyEndTime = this.lastApplyEndTime ? this.lastApplyEndTime : moment(this.oldApplyEndTime).format('YYYY-MM-DD HH:mm:ss')
    } else if ((new Date(time)).getTime() > (new Date(this.form.activityEndTime)).getTime()) {
      this.$message.error('报名截止时间不能晚于服务结束时间！')
      this.form.applyEndTime = this.lastApplyEndTime ? this.lastApplyEndTime : moment(this.oldApplyEndTime).format('YYYY-MM-DD HH:mm:ss')
    } else {
      this.form.applyEndTime = time
      this.lastApplyEndTime = time
    }
  }

  @Watch("form.place")
  changeMap(val: any) {
    //this.address_detail += val
    this.address_detail = this.provincecityarea + val;
    this.setPlace();
  }

  async created() {
    console.log((this as any).$route.params.id);
    // if ((this as any).$route.params.id) {
    this.dataId = Number((this as any).$route.params.id);
    if (this.dataId > 0) {
      await api.activity.getForEdit({id: this.dataId}).then((res) => {
        this.form = res.data!;
        this.$set(this.form, 'applyEndTime', moment(res.data?.applyEndTime).format('YYYY-MM-DD HH:mm:ss'));
        this.oldApplyEndTime = res.data?.applyEndTime;
        this.oldEndTime = res.data?.activityEndTime;
        this.selectedOptions = [
          res.data!.provinceId + "",
          res.data!.cityId + "",
          res.data!.areaId + ""
        ];
        if (res.data && res.data.signUpFormSchemaList) {
          this.activitySignUpFormSchema = res.data!.signUpFormSchemaList
          if (this.activitySignUpFormSchema&&this.activitySignUpFormSchema.length>0){
            this.activitySignUpFormSchema.forEach((res)=>{
              console.log("this.selectValues",this.selectValues)
              let index=this.selectValues.findIndex(d=>d.value==res.filedName)
              this.selectValues[index].isSelect=true
            })
          }
        }
      });
      if (this.dataId == 0) {
        this.form.activityStartTime = Date.now();
        this.form.activityEndTime = Date.now();
      }
    }
    // }

    await this.fetchDataDictionaryList();

    await this.getLeaderList();

    this.form.isVolunteerActivity = !!this.form.isVolunteerActivity;

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async fetchDataDictionaryList() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        maxResultCount: 65535,
        key: "VolunteerLevel"
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.volunteerLevelList = res.items!;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({
        maxResultCount: 65535,
        key: "ActivityType"
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.activityTypeDictionaries = res.items!;
      });
  }

  async getLeaderList() {
    await api.volunteer.getApprovedVolunteerList().then((res: UserDto[]) => {
      this.leaderList = [...res];
    });
  }

  async save(isPublish: boolean) {
    this.dataForm.validate(async (valid: boolean) => {
      if (valid) {
        this.form.isPublish = isPublish;

        if (this.activitySignUpFormSchema && this.activitySignUpFormSchema.length > 0) {
          let error = false
          this.activitySignUpFormSchema.forEach((res) => {
            if (error) {
              return
            }
            if (!res.name) {
              error = true
            }
          })
          if (error) {
            this.$message({
              type: "error",
              message: "请检查报名模板中是否有输入项名称未填写的项",
            });
            return
          }
          this.form.signUpFormSchemas = this.activitySignUpFormSchema;
        }


        this.btnDisabled = true;
        if (this.form!.id) {
          await api.activity.update({body: this.form}).finally(() => {
            this.btnDisabled = false
          });
        } else {
          await api.activity.create({body: this.form}).finally(() => {
            this.btnDisabled = false
          });
        }
        (this as any).$router.back();
      } else {
        (this as any).$message.error("请检查表单！");
      }
    });
  }

  cancel() {
    (this as any).$router.back();
  }

  hasDisplayPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }

  @Watch("dd")
  onActivityDateChange(value: any) {
    if (value && value.length === 2) {
      this.form!.activityStartTime = moment(value[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.form!.activityEndTime = moment(value[1]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    } else {
      this.form!.activityStartTime = "";
      this.form!.activityEndTime = "";
    }
  }

  @Watch("t1")
  onActivityStartTimeChange(value: any) {
    if (value) {
      let _t = value.split(":");
      this.form!.activityStartTime = moment(this.form!.activityStartTime)
        .hour(parseInt(_t[0]))
        .minute(parseInt(_t[1]))
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(this.form!.activityStartTime);
    }
  }

  get dd() {
    return [this.form!.activityStartTime, this.form!.activityEndTime];
  }

  set dd(e) {
    if (e && e.length === 2) {
      if (new Date(e[1]!).getTime() < new Date(this.oldEndTime).getTime()) {
        this.$message.error('服务结束时间只能增加，不能减少！')
        this.form.activityEndTime = this.lastEndTime ? this.lastEndTime : moment(this.oldEndTime).format('YYYY-MM-DD HH:mm:ss')
      } else {
        console.log(e);
        this.form!.activityStartTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        this.form!.activityEndTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
        this.lastEndTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
      }
    }
  }

  get t1() {
    return moment(this.form!.activityStartTime).format("HH:mm");
  }

  set t1(e) {
    if (e) {
      let _t = e.split(":");
      this.form!.activityStartTime = moment(this.form!.activityStartTime)
        .hour(parseInt(_t[0]))
        .minute(parseInt(_t[1]))
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(this.form!.activityStartTime);
    }
  }

  get t2() {
    return moment(this.form!.activityEndTime).format("HH:mm");
  }

  set t2(e) {
    if (e) {
      let _t = e.split(":");
      this.form!.activityEndTime = moment(this.form!.activityEndTime)
        .hour(parseInt(_t[0]))
        .minute(parseInt(_t[1]))
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(this.form!.activityEndTime);

      let difDays = moment(this.form!.activityEndTime).diff(
        moment(this.form!.activityStartTime),
        "days"
      );
      let difMin = moment(this.form!.activityEndTime).diff(
        moment(this.form!.activityStartTime),
        "minutes"
      );

      console.log(`difDays:${difDays},difMin:${difMin}`);

      this.form.totalHours = (difMin - difDays * 24 * 60) / 60;
    }
  }

  @Watch("t2")
  onActivityEndTimeChange(value: any) {
    if (value) {
      let _t = value.split(":");
      this.form!.activityEndTime = moment(this.form!.activityEndTime)
        .hour(parseInt(_t[0]))
        .minute(parseInt(_t[1]))
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(this.form!.activityEndTime);

      let difDays = moment(this.form!.activityEndTime).diff(
        moment(this.form!.activityStartTime),
        "days"
      );
      let difMin = moment(this.form!.activityEndTime).diff(
        moment(this.form!.activityStartTime),
        "minutes"
      );

      console.log(`difDays:${difDays},difMin:${difMin}`);

      this.form!.totalHours = (difMin - difDays * 24 * 60) / 60;
    }
  }

  //   省市区
  handleChange(value: any, label: any) {
    this.form!.provinceId = value[0];
    this.form!.cityId = value[1];
    this.form!.areaId = value[2];
    this.address_detail =
      CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]];
    this.provincecityarea =
      CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]];
    this.setPlace();
  }

  map_handler(result: any) {
    this.BMap = result.BMap;
    this.map = result.map;
  }

  //地址
  setPlace() {
    let th = this as any;

    if (this.map) {
      this.map.clearOverlays(); //清除地图上所有覆盖物


      let local = new th.BMap.LocalSearch(th.map, {
        //智能搜索
        onSearchComplete: function () {
          if (local && local.getResults().getPoi(0)) {
            console.log(local.getResults().getPoi(0));
            th.userlocation = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
            th.map.centerAndZoom(th.userlocation, 18);
            th.map.addOverlay(new th.BMap.Marker(th.userlocation)); //添加标注
            th.form!.placeCoordinate =
              "" + th.userlocation.lng + "," + th.userlocation.lat;
          }
        },
      });
      local.search(th.address_detail);
      console.log(th.address_detail);
      th.map.addEventListener("click", function (e: any) {
        th.form!.placeCoordinate =
          "" + th.userlocation.lng + "," + th.userlocation.lat;
      });
    }
  }


  activitySignUpFormSchema: ActivitySignUpFormSchemaDto[] = [{
    name: "",
    required: false,
    placeholder: "请输入",
    filedName: "",
    value: ""
  }]

  selectValues: any[] = [
    {text: "姓名", value: 'Name', isSelect: false},
    {text: "手机号", value: "PhoneNumber", isSelect: false},
    {text: "报名角色名称", value: "Role", isSelect: false},
    {text: "志愿者编号", value: "VolunteerCode", isSelect: false},
    {text: "志愿星级", value: "VolunteerLevel", isSelect: false},
    {text: "所属志愿团体", value: "OrgName", isSelect: false},
    {text: "技能熟练程度", value: "ProficiencyLevel", isSelect: false},
    {text: "所在地（区域）", value: "Address", isSelect: false},
  ]

  handleAddItem() {
    this.activitySignUpFormSchema.push({name: "", required: false, placeholder: "请输入", filedName: "", value: ""})
    this.$nextTick(() => {
      const container = document.getElementById('form-container') as any; // 替换为实际的表单容器ID或类名
      container.scrollTop = container.scrollHeight;
    });
  }

  handleDeleteItem(index: number) {
    this.activitySignUpFormSchema.splice(index, 1);
  }

  handleCheckBoxChange(text: string, value: string, isSelect: boolean) {
    if (isSelect) {
      this.activitySignUpFormSchema.push({
        name: text,
        required: false,
        placeholder: "请输入",
        filedName: value,
        value: ""
      })
    } else {
      const index = this.activitySignUpFormSchema.findIndex(d => d.filedName == value)
      this.activitySignUpFormSchema.splice(index, 1);
    }
    console.log("event", text)
  }


}
