





























































































































































































































































































































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";

import {
  DataDictionaryDto,
  OrganizationUnitDto,
  OrganizationUnitDtoPagedResultDto,
  PointType,
  SelectListItem,
  VolunteerActivityCountDto,
  VolunteerDashbordBasicDto,
  VolunteerDashbordCmsCount,
  VolunteerDashbordCountDto,
  VolunteerPointRankingDto,
  VolunteerServiceCountDto,
  VolunteerTotalHoursRankingDto
} from "@/api/appService";
import echarts from "echarts";
import "echarts/lib/chart/map";
import "echarts/map/js/china.js";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import moment from "moment";
import VolunteerDashboardCountBoard from "@/components/Dashboard/VolunteerDashboardCountBoard.vue";
import getPdf from '@/utils/exportPDF';

@Component({
  name: "VolunteerDashboard",
  components: {
    VolunteerDashboardCountBoard,
  },
})
export default class InternalDashboard extends Vue {
  year: number | undefined = undefined;
  yearView = "";
  platformData: VolunteerDashbordCountDto = {
    /** 服务数量 */
    serviceCount: 0,

    /** 培养数量 */
    trainCount: 0,

    /** 活动数量 */
    activityCount: 0,

    /** 志愿者数量 */
    volunteerCount: 0,

    /** 志愿团体数量 */
    orgCount: 0
  };
  orgList: OrganizationUnitDto[] = [];
  orgStatisticsDataQuery: any = {
    orgId: undefined,
    beginTime: undefined,
    endTime: undefined,
    pointType: undefined,
    detailTypeId: undefined
  };
  orgStatisticsData: VolunteerDashbordCountDto[] = []
  totalHoursRankingList: VolunteerTotalHoursRankingDto[] = [];
  yearRanking: any = [];
  timeRanking: any = [];
  pointTypeList: SelectListItem[] = [];
  detailTypeList: DataDictionaryDto[] = [];
  pointRankingList:VolunteerPointRankingDto[]=[];
  volunteerDashbordBasicDto:VolunteerDashbordBasicDto= {};
  volunteerServiceCountDto:VolunteerServiceCountDto= {};
  volunteerActivityCountDto:VolunteerActivityCountDto= {};
  volunteerDashbordCmsCount:VolunteerDashbordCmsCount= {};

  option: any  = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: '平台年数据',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 15,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: this.platformData.serviceCount, name: '年度发布服务数量' },
          { value: this.platformData.trainCount, name: '年度发布培养数量' },
          { value: this.platformData.activityCount, name: '年度发布活动数量' },
          { value: this.platformData.volunteerCount, name: '年度志愿者总人数' },
          { value: this.platformData.orgCount, name: '年度志愿团体数量' }
        ]
      }
    ]
  };

  option2: any  = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: '平台年数据',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 15,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: this.platformData.serviceCount, name: '年度发布服务数量' },
          { value: this.platformData.trainCount, name: '年度发布培养数量' },
          { value: this.platformData.activityCount, name: '年度发布活动数量' },
          { value: this.platformData.volunteerCount, name: '年度志愿者总人数' },
          { value: this.platformData.orgCount, name: '年度志愿团体数量' }
        ]
      }
    ]
  };
  option6 :any = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: ["11","22"]
    },
    series: [
      {
        name: '服务',
        type: 'bar',
        data: [18203, 23489, 29034, 104970, 131744, 630230]
      },
      {
        name: '培养',
        type: 'bar',
        data: [19325, 23438, 31000, 121594, 134141, 681807]
      },
      {
        name: '活动',
        type: 'bar',
        data: [19325, 23438, 31000, 121594, 134141, 681807]
      }
    ]
  };

  created():void {
    let date = new Date();
    const year = date.getFullYear();
    this.year = year;
    this.yearView = String(year);
    this.getPlatformData();
    this.getOrgList();
    this.fetchEnum();
    this.yearRanking = String(year);
    this.orgStatisticsDataQuery.beginTime = moment().subtract(1, 'year').format("YYYY-MM-DD");
    this.orgStatisticsDataQuery.endTime = moment().month(moment().month())
      .endOf("month")
      .format("YYYY-MM-DD");
    this.yearRanking = [this.orgStatisticsDataQuery.beginTime, this.orgStatisticsDataQuery.endTime];
    this.timeRanking = [moment().subtract(1, 'day').format("YYYY-MM-DD"),  moment().format("YYYY-MM-DD HH:mm:ss")];
    this.getOrgStatisticsData()
    this.getPlatformDataWebsiteViewCount();
    this.getBasicInformationCount()
    this.getServiceCount()
    this.getActivityCount()
    this.getCmsCount()
  }
  webSiteloading=false
  getPlatformDataWebsiteViewCount(): void {
    if (this.timeRanking && this.timeRanking.length === 2) {
      this.webSiteloading=true;
      api.volunteerDashboard.getPlatformDataWebsiteViewCount({
        beginTime:moment(this.timeRanking[0]).format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(this.timeRanking[1]).format("YYYY-MM-DD HH:mm:ss")
      }).then((res)=>{
        this.volunteerDashbordBasicDto.pvWebpageViews=res.pvWebpageViews
        this.volunteerDashbordBasicDto.usersReach=res.usersReach
        this.webSiteloading=false;
      })
    }
  }
  basicInformationloading=false
  getBasicInformationCount(): void {
    if (this.yearRanking && this.yearRanking.length === 2) {
      this.basicInformationloading=true;
      api.volunteerDashboard.getBasicInformationCount({
        beginTime:moment(this.yearRanking[0]).format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(this.yearRanking[1]).format("YYYY-MM-DD HH:mm:ss")
      }).then((res)=>{
        this.volunteerDashbordBasicDto.numberOfRegisteredUsers=res.numberOfRegisteredUsers
        this.volunteerDashbordBasicDto.numberOfServiceRecipients=res.numberOfServiceRecipients
        this.volunteerDashbordBasicDto.numberOfVolunteersRegistered=res.numberOfVolunteersRegistered
        this.volunteerDashbordBasicDto.numberOfRegisteredVolunteers=res.numberOfRegisteredVolunteers
        this.basicInformationloading=false;
      })
    }
  }

  allSearch(){
    this.getServiceCount()
    this.getActivityCount()
    this.getCmsCount()
  }


  serviceLoading=false
  getServiceCount(): void {
    if (this.yearRanking && this.yearRanking.length === 2) {
      this.serviceLoading=true;
      api.volunteerDashboard.getServiceCount({
        beginTime:moment(this.yearRanking[0]).format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(this.yearRanking[1]).format("YYYY-MM-DD HH:mm:ss")
      }).then((res)=>{
        this.volunteerServiceCountDto=res;
        this.serviceLoading=false;
      })
    }
  }
  activityLoading=false
  getActivityCount(): void {
    if (this.yearRanking && this.yearRanking.length === 2) {
      this.activityLoading=true;
      api.volunteerDashboard.getActivityCount({
        beginTime:moment(this.yearRanking[0]).format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(this.yearRanking[1]).format("YYYY-MM-DD HH:mm:ss")
      }).then((res)=>{
        this.volunteerActivityCountDto=res;
        this.activityLoading=false;
      })
    }
  }
  cmsLoading=false
  getCmsCount(): void {
    if (this.yearRanking && this.yearRanking.length === 2) {
      this.cmsLoading=true;
      api.volunteerDashboard.getCmsCount({
        beginTime:moment(this.yearRanking[0]).format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(this.yearRanking[1]).format("YYYY-MM-DD HH:mm:ss")
      }).then((res)=>{
        this.volunteerDashbordCmsCount=res;
        this.cmsLoading=false;
      })
    }
  }

  //平台数据
  queryPlatformData(): void {
    if (this.yearView) {
      this.year = Number(this.yearView);
      this.getPlatformData()
    }
  }

  getPlatformData(): void {
    api.volunteerDashboard.getPlatformData({year: this.year}).then((res: VolunteerDashbordCountDto) => {
      this.platformData = res;
      this.option.series[0].data = [
        { value: this.platformData.serviceCount, name: '年度发布服务数量' },
        { value: this.platformData.trainCount, name: '年度发布培养数量' },
        { value: this.platformData.activityCount, name: '年度发布活动数量' },
      ]
      this.option2.series[0].data = [
        { value: this.platformData.volunteerCount, name: '年度志愿者总人数' },
        { value: this.platformData.orgCount, name: '年度志愿团体数量' }
      ]

      const myChart = echarts.init(this.$refs.yearData as HTMLDivElement);
      myChart.setOption(this.option);
      const myChart2 = echarts.init(this.$refs.memberData as HTMLDivElement);
      myChart2.setOption(this.option2);
    })
  }

  handleExport() {
    getPdf.downloadPDF("#dashboard-detail", "平台年数据")
  } handleExport2() {
    getPdf.downloadPDF("#dashboard-detail2", "志愿团体数据统计")
  }handleExport3() {
    getPdf.downloadPDF("#dashboard-detail3", "公益积分")
  }
  //合作机构
  getOrgList():void {
    api.organizationUnit.getAll({maxResultCount: 65535}).then((res: OrganizationUnitDtoPagedResultDto) => {
      this.orgList = res.items!;
    })
  }
orgloding=false
  getOrgStatisticsData():void {
    this.orgloding=true;
    api.volunteerDashboard.getOrgStatisticsData(this.orgStatisticsDataQuery).then((res: VolunteerDashbordCountDto[]) => {
      this.orgStatisticsData = res;
      this.option6.yAxis.data = []

      this.option6.yAxis.data =  this.orgStatisticsData
        .filter(d => d.orgName !== undefined)
        .map(d => d.orgName);

      this.option6.series=[
        {
          name: '服务',
          type: 'bar',
          data: this.orgStatisticsData
            .filter(d => d.serviceCount !== undefined)
            .map(d => d.serviceCount)
        },
        {
          name: '培养',
          type: 'bar',
          data: this.orgStatisticsData
            .filter(d => d.trainCount !== undefined)
            .map(d => d.trainCount)
        },
        {
          name: '活动',
          type: 'bar',
          data: this.orgStatisticsData
            .filter(d => d.activityCount !== undefined)
            .map(d => d.activityCount)
        }
      ]
      this.orgloding=false;
      const myChart = echarts.init(this.$refs.orgService as HTMLDivElement);
      myChart.setOption(this.option6);
    })
    // api.volunteerDashboard.getTotalHoursRanking(this.orgStatisticsDataQuery).then((res: VolunteerTotalHoursRankingDto[]) => {
    //   this.totalHoursRankingList = res;
    // })
    this.pointRankingList=[]
    api.volunteerDashboard.getPointRanking(this.orgStatisticsDataQuery).then((res:VolunteerPointRankingDto[])=>{
      this.pointRankingList=res;
    })
  }

  queryOrgStatisticsData(): void {
    if (this.yearRanking && this.yearRanking.length === 2) {
      this.orgStatisticsDataQuery.beginTime = moment(this.yearRanking[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.orgStatisticsDataQuery.endTime = moment(this.yearRanking[1]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.getOrgStatisticsData();
    }
  }

  //table背景色
  tableRowClassName({row, rowIndex}: any):string {
    if (rowIndex === 0) {
      return 'table-1-row';
    } else if (rowIndex === 1) {
      return 'table-2-row';
    } else if (rowIndex === 2) {
      return 'table-3-row';
    }
    return '';
  }

  getDetailTypeList():void {
    let key = '';
    let pointType=this.orgStatisticsDataQuery.pointType;
    this.orgStatisticsDataQuery.detailTypeId=undefined;
    switch (pointType) {
      case PointType.TrainRecord:
        key = 'TrainType';
        break;
      case PointType.ServiceRecord:
        key = 'ServiceType';
        break;
      case PointType.HelpApply:
        key = '';
        break;
      case PointType.BBS:
        key = 'BbsPointType';
        break;
      case PointType.Volunteer:
        key = 'Volunteer';
        break;
      case PointType.ActivityRecord:
        key = 'ActivityType';
        break;
      default:
        break;

    }
    if (key) {
      api.dataDictionary
        .getDataDictionaryListByKey({key: key})
        .then((res) => {
          this.detailTypeList = res.items!;
        });
    } else {
      this.detailTypeList = [];
    }
  }

  fetchEnum():void {
    api.enumService
      .getValues({typeName: "PointType"})
      .then((res) => {
        this.pointTypeList = res;
      });
  }
}
