














































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {ExamPaperMiniDto, ExamSourceType, QuestionAnalysisDto, TrainApplyDto} from "@/api/appService";
import ChoiceItemAttachment from "@/components/ChoiceItemAttachment/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AttachmentListItem from "@/components/AttachmentsView/AttachmentListItem.vue";
import MakeUp from "@/views/train/trainApply/component/makeUp.vue";


@Component({
  name: "ExamPaperDetail",
  components: {
    MakeUp,
    AttachmentListItem,
    AttachmentsView,
    PagedTableView, ChoiceItemAttachment
  },
})
export default class ExamPaperDetail extends Vue {
  @Prop() userId!: number;
  @Prop() trainId!: number;

  @Prop() trainApplyId!: number;

  @Prop() starRatingId!: number;
  @Prop() isCanAudit!: boolean;

  paper: ExamPaperMiniDto = {};
  trainApply: TrainApplyDto = {};
  editLinkId = 0;

  analysisList: QuestionAnalysisDto[] = [];

  handleMakeUp(item:any) {
    this.editLinkId = this.trainApplyId!;
    (this.$refs.makeUpForm as any).show = true;
  }

  created() {
    if (this.trainApplyId > 0) {
      api.trainApply.get({id:this.trainApplyId}).then(res => {
       this.trainApply=res;
      });
    }
    if (this.trainId > 0) {
      api.examQuestion.getLatestTrainPaperId({userId: this.userId, trainId: this.trainId}).then(res => {
        this.paper = res;
        if (this.paper && this.paper.id) {
          this.getDetail();
        }
      });
    } else if (this.starRatingId > 0) {
      api.examQuestion.getLatestTrainPaperId({
        userId: this.userId,
        trainId: this.starRatingId,
        sourceType: ExamSourceType.StarRating
      }).then(res => {
        this.paper = res;
        if (this.paper && this.paper.id) {
          this.getDetail();
        }
      });
    }
  }
  reviewedPaper(){
    api.examQuestion.reviewedPaper({paperId:this.paper.id}).then((res)=>{
      this.$message({
        type: "success",
        message: "阅卷结束!",
      });
      api.examQuestion.getLatestTrainPaperId({userId: this.userId, trainId: this.trainId}).then(res => {
        this.paper = res;
      });
    })
  }
  updatePaperItem(id:any,point:any){
    console.log(id)
    console.log(point)
    api.examQuestion.updatePaperItem({body:{point:point,examPaperItemId:id}}).then((res)=>{
      this.paper.score=res
    })
  }
  getDetail() {
    api.examQuestion.getQuestionAnalysisList({paperId: this.paper.id}).then(res => {
      this.analysisList = res;
      this.analysisList.forEach((anal)=>{
        if ( anal.answerValueFileIds){
          api.attachmentService.getAttachmentsByIds({ids:anal.answerValueFileIds}).then((att)=> {
            this.$set(anal, 'attachment', att); // 使用 $set 以确保响应式更新
          })
        }
      })
    })
  }
}
