






































































































import api from "@/api";
import { Component, Vue, Ref } from "vue-property-decorator";
import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  ServiceOrganizationDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  components: {ExportButton, PagedTableView },
})
export default class ServiceOrganizationList extends Vue {
  @Ref() readonly pagedTableView!: any;
  editId = 0;
  queryForm = {
    Name: "",
    ServiceOrganizationTypeId: undefined,
  };
  ServiceOrganizationTypeList: DataDictionaryDto[] = [];

  fetchData(params: any) {
    // debugger;
    return api.serviceOrganization.getAll({
      name: this.queryForm.Name,
      serviceOrganizationTypeId: this.queryForm.ServiceOrganizationTypeId,
    });
  }

  created() {
    this.fetchServiceOrganizationTypeList();
  }

  async fetchServiceOrganizationTypeList() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ServiceOrganizationType",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.ServiceOrganizationTypeList = res!.items!;
      });
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "serviceOrganizationCreate",
    });
  }

  // 跳转详情页
  handleDetail(index: number, row: ServiceOrganizationDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "serviceOrganizationDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 跳转编辑页
  handleEdit(index: number, row: ServiceOrganizationDto) {
    this.$router.push({
      name: "serviceOrganizationEdit",
      params: { id: row.id!.toString() },
    });
  }

  // 删除
  handleDelete(item: number, row: ServiceOrganizationDto) {
    this.$confirm("您确定删除服务机构吗?", "提示").then(() => {
      api.serviceOrganization.delete({ id: row.id }).then((res) => {
        this.$message.success("删除成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
