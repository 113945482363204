
















































































import PagedTableView from "@/components/PagedTableView/index.vue";
import {Vue, Component, Watch} from "vue-property-decorator";
import api from "@/api";
import {StudentFeedbackRecordDto} from "@/api/appService";
import moment from "moment";
import EnumTypeTag from "@/components/enumTypeTagFormatter/EnumTypeTag.vue";

interface FeedbackRecordGetAll {
  /**  */
  title?: string;
  /**  */
  studentId?: number;
  /**  */
  studentName?: string;
  /**  */
  studentNo?: string;
  /**  */
  schoolId?: number;
  /**  */
  creatorUserId?: number;
  /**  */
  year?: number;
  /**  */
  term?: number;
  /**  */
  creationStartTime?: string;
  /**  */
  creationEndTime?: string;
}

@Component({
  name: "StudentFeedbackRecord",
  components: {
    EnumTypeTag,
    PagedTableView
  }
})
export default class ContactFeedBackRecord extends Vue {
  queryForm: FeedbackRecordGetAll = {
    title: undefined,
    studentName: undefined,
    schoolId: undefined,
    year: undefined,
    term: undefined,
    creationStartTime: undefined,
    creationEndTime: undefined,
    creatorUserId: undefined,
    studentId: undefined
  };
  termList = [{
    id: 1,
    name: "第一学期"
  }, {
    id: 2,
    name: "第二学期"
  }];
  gradeList: any[] = [];
  creatorUserId: number | undefined = undefined;
  studentId: number | undefined = undefined;
  isShowAddButton = true

  created() {
    if (this.$route.params.userExtendId) {
      this.creatorUserId = Number(this.$route.params.userExtendId);
      this.studentId = undefined;
      this.isShowAddButton = false
    }
    if (this.$route.params.studentId) {
      this.creatorUserId = undefined;
      this.studentId = Number(this.$route.params.studentId);
    }
    this.getGradeList();
  }

  get pageHeadContent() {
    if (this.creatorUserId) {
      return `学校联系人反馈记录`;
    } else if (this.studentId) {
      return `学生反馈记录`;
    } else {
      return "Loading...";
    }
  }

  async getGradeList() {
    this.gradeList = await api.enumService.getValues({typeName: "StudentGrade"});
  }

  fetchData(params?: any) {
    params.creatorUserId = this.creatorUserId;
    params.studentId = this.studentId;
    return api.studentFeedbackRecord.getAll(params);
  }


  termFormatter(row: any) {
    return row.term === 1 ? "第一学期" : "第二学期";
  }

  timeFormatter(row: any) {
    return moment(String(row.creationTime)).format("YYYY-MM-DD HH:mm:ss");
  }

  handleDetail(index: number, row: StudentFeedbackRecordDto) {
    this.$router.push({
      name: "studentFeedbackRecordDetail",
      params: {
        id: row.id!.toString()
      }
    });
  }

  addStudentFeedbackRecordInfo() {
    console.log("studentId=" + this.$route.params.studentId);
    this.$router.push({
      name: "studentFeedbackRecordAdd",
      params: {
        schoolId: this.$route.params.schoolId,
        studentId: this.$route.params.studentId
      }
    });
  }

  handleEdit(index: number, row: StudentFeedbackRecordDto) {
    this.$router.push({
      name: "studentFeedbackRecordEdit",
      params: {
        schoolId: this.$route.params.schoolId,
        id: row.id!.toString()
      }
    });
  }

  handleDelete(index: number, row: StudentFeedbackRecordDto) {
    this.$confirm("你确定删除吗?", "提示").then(() => {
      api.studentFeedbackRecord.delete({id: row.id}).then(() => {
        this.$message.success("删除成功");
      });
    });

  }
}
