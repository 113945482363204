

























































































































import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  CustomFormType,
  TrainDto,
  TrainDtoPagedResultDto,
  TrainManagementCreateOrUpdateDto,
  TrainMethod,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import moment from "moment";

import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";

@Component({
  components: {
    CustomFormEdit,
  },
})
export default class EditFundMaintain extends Vue {
  @Ref("customForm") customForm!: CustomFormEdit;

  @Ref() readonly dataForm!: ElForm;
  dataId = 0;
  defaultData: TrainManagementCreateOrUpdateDto = {};
  trainList: TrainDto[] = [];
  loading = true;
  form: TrainManagementCreateOrUpdateDto = {
    extensionObject: {},
  };
  trainInfo: any = {
    trainType: "",
    trainTime: "",
    point: 0,
    lecturerName: "",
    place: ""
  };
  checkInCount = 0;

  get hostType() {
    return CustomFormType.TrainManage;
  }

  get titleDisabled() {
    return this.dataId > 0;
  }

  created() {
    this.getTrainList();
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.trainManagement.getForEdit({id: this.dataId}).then((res) => {
        this.form = res;
        this.getTrainDetail();
      });
    }
  }

  getTrainDetail() {
    api.train
      .get({id: this.form.trainId})
      .then((res: TrainDto) => {
        if (res) {
          if (res.trainMethod === TrainMethod.Online) {
            this.trainInfo.trainType = "线上"
          } else if (res.trainType && res.trainMethod === TrainMethod.Offline) {
            this.trainInfo.trainType = res.trainType.displayName
          } else {
            this.trainInfo.trainType = ""
          }
          if (res.trainStartTime && res.trainEndTime) {
            this.trainInfo.trainTime = moment(res.trainStartTime).format("YYYY-MM-DD HH:mm") +
              "至" + moment(res.trainEndTime).format("YYYY-MM-DD HH:mm")
          } else {
            this.trainInfo.trainTime = ""
          }
          if (res.point) {
            this.trainInfo.point = res.point
          } else {
            this.trainInfo.point = 0
          }
          if (res.lecturerUser) {
            this.trainInfo.lecturerName = res.lecturerUser?.surname
          } else if(res.lecturer) {
            this.trainInfo.lecturerName = res.lecturer?.name
          } else {
            this.trainInfo.lecturerName = ""
          }
          this.trainInfo.place = (res.province ?.provinceName??'') +
            (res.city ? res.city.cityName : '') +
            (res.area ? res.area.areaName : '') +
            (res.address ? res.address : '')
        } else {
          this.trainInfo = {
            trainType: "",
            trainTime: "",
            point: 0,
            lecturerName: "",
            place: ""
          }
        }
      });
    api.train
      .getCheckInCount({trainId: this.form.trainId})
      .then((res) => {
        this.checkInCount = res
      });
  }

  getTrainList() {
    api.train.getAll({maxResultCount: 65535}).then((res: TrainDtoPagedResultDto) => {
      this.trainList = res.items!;
    });
  }

  get getData() {
    if (this.dataId > 0)
      return api.trainManagement.getForEdit({id: this.dataId});
    else return this.defaultData;
  }

  handleSave() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        (this.$refs.customForm as any).validate(async (valid2: boolean) => {
          if (valid2) {
            const data = (
              this.customForm as any
            ).getRequestData() as TrainManagementCreateOrUpdateDto;
            data.extensionData = JSON.stringify(data.extensionObject);
            let submitForm: TrainManagementCreateOrUpdateDto = {
              trainId: this.form.trainId,
              volunteerCount: this.checkInCount,
              formId: data.formId,
              extensionObject: data.extensionObject,
              extensionData: data.extensionData,
              id: this.dataId,
            };

            let fn;
            if (this.dataId > 0) fn = api.trainManagement.update;
            else fn = api.trainManagement.create;

            fn({body: submitForm}).then((res) => {
              if (this.dataId > 0) {
                this.$message.success("更新成功");
              } else {
                this.$message.success("新增成功");
              }
              this.$router.push({
                name: "trainManagementTableList",
              });
            });
          } else {
            this.$message.error("请检查表单");
          }
        });

        // if (this.form!.id) {
        //   await api.lecturer.update({
        //     body: this.form,
        //   });
        // } else {
        //   await api.lecturer.create({
        //     body: this.form,
        //   });
        // }
        // this.$router.back();
        // this.$message.success("更新成功");
      }
    });

    // (this.$refs.customForm as any).validate(async (valid: boolean) => {
    //   if (valid) {
    //     const data = (this.customForm as any).getRequestData() as TrainManagementCreateOrUpdateDto;
    //     data.extensionData=JSON.stringify(data.extensionObject)
    //     let fn;
    //     if (this.dataId) fn = api.trainManagement.update;
    //     else fn = api.trainManagement.create;
    //
    //     fn({body: data}).then((res) => {
    //       if (this.dataId) {
    //         this.$message.success("更新成功");
    //       } else {
    //         this.$message.success("新增成功");
    //       }
    //       this.$router.push({
    //         name: "trainManagementTableList",
    //       });
    //     });
    //   } else {
    //     this.$message.error('请检查表单')
    //   }
    // })
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    trainId: [
      {
        required: true,
        message: "培养主题 必填",
        trigger: "blur",
      },
    ],
    sex: [
      {
        required: true,
        message: "性别必选",
        trigger: "blur",
      },
    ],
    areaId: [
      {
        required: true,
        message: "居住地址必填",
        trigger: "blur",
      },
    ],
    address: [
      {
        required: true,
        message: "详细地址必填",
        trigger: "blur",
      },
    ],
  };
}
