

















import { PropType } from 'vue'
import {AttachmentDto} from "@/api/appService";

export default {
  name: 'AttachmentListItem',
  props: {
    item: {
      type: Object as PropType<AttachmentDto>,
      required: true
    }
  },
  methods: {
    download(item: AttachmentDto) {
      window.open(item.url)
    }
  },
  computed: {
  }
}
