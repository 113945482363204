





























































































































import api from "@/api";
import {Component, Vue, Ref} from "vue-property-decorator";
import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto, ServiceOrganizationDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  components: {ExportButton, PagedTableView},
})
export default class ServiceManagementList extends Vue {
  @Ref() readonly pagedTableView!: any;
  editId = 0;
  queryForm: any = {
    title: undefined,
    leaderUSerName: undefined,
    host: undefined,
    serviceOrganizationId: undefined,
    serviceStartTime: undefined,
    serviceEndTime: undefined
  };
  serviceOrganizationList: ServiceOrganizationDto[] = [];

  async fetchData(params: any) {
    // debugger;
    return await api.serviceManagement.getAll(params);
  }

  created() {
    this.fetchDictionary();
  }

  async fetchDictionary() {
    //服务机构
    await api.serviceOrganization.getAllServiceOrganizationList().then((res: any) => {
      this.serviceOrganizationList = res!;
    })
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "serviceManagement-create",
    });
  }

  // 跳转详情页
  handleDetail(index: number, row: any) {
    this.editId = row.id!;
    this.$router.push({
      name: "serviceManagement-detail",
      params: {id: row.id!.toString()},
    });
  }

  // 跳转编辑页
  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "serviceManagement-edit",
      params: {id: row.id!.toString()},
    });
  }

  // 删除
  handleDelete(item: number, row: any) {
    this.$confirm("您确定删除该星级评定总结表吗?", "提示").then(() => {
      api.serviceManagement.delete({id: row.id}).then((res) => {
        this.$message.success("删除成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
