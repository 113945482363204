





































































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";

import {ActivityDto, PagedResultDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "ActivityList",
  components: {
    ExportButton,
    PagedTableView,
  },
})
export default class ActivityList extends Vue {
  queryForm = {
    title: "",
    status: "",
  };

  activityStatusList: any[] = [
    {
      disabled: false,
      group: null,
      selected: false,
      text: "预录入(保存)",
      value: "Saved",
    },
    // {
    //   disabled: false,
    //   group: null,
    //   selected: false,
    //   text: "退回",
    //   value: "SendBacked",
    // },
    // {
    //   disabled: false,
    //   group: null,
    //   selected: false,
    //   text: "提交",
    //   value: "Submited",
    // },
    // {
    //   disabled: false,
    //   group: null,
    //   selected: false,
    //   text: "审批通过",
    //   value: "Approved",
    // },
    {
      disabled: false,
      group: null,
      selected: false,
      text: "已经发布",
      value: "Published",
    },
    // {
    //   disabled: false,
    //   group: null,
    //   selected: false,
    //   text: "结束",
    //   value: "Finished",
    // },
    // {
    //   disabled: false,
    //   group: null,
    //   selected: false,
    //   text: "删除",
    //   value: "Deleted",
    // },
  ];

  id = 0;

  created() {
    // api.enumService
    //   .getValues({ typeName: "ActivityStatus" })
    //   .then((res: any) => {
    //     this.activityStatusList = res;
    //   });
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.activity.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "ActivityEdit",
      params: {id: "0"},
    });
  }

  // 编辑
  handleEdit(index: number, row: ActivityDto) {
    this.id = row.id!;
    this.$router.push({
      name: "ActivityEdit",
      params: {id: row.id! + ""},
    });
  }

  // 发布
  handlePublish(index: number, item: ActivityDto) {
    this.$confirm("确定发布吗?", "提示").then(() => {
      api.activity.publish({body: {id: item.id}}).then((res) => {
        this.$message.success("发布成功");
      });
    });
  }

  // 取消发布
  handleCancelPublish(index: number, item: ActivityDto) {
    this.$confirm("确定取消发布吗?", "提示").then(() => {
      api.activity.cancelPublish({body: {id: item.id}}).then((res) => {
        this.$message.success("取消成功");
      });
    });
  }

  // 删除
  async handleDelete(index: number, row: ActivityDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.activity
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handleQr(index: number, row: ActivityDto) {
    this.$message({
      type: "info",
      message: "待开发",
    });
  }

  handleCheckIn(index: number, row: ActivityDto) {
    this.$router.push({
      name: "ActivityCheckIn",
      params: {id: `${row.id!}`},
      query: {},
    });
  }

  handleDetail(index: number, row: ActivityDto) {
    this.$router.push({
      name: "ActivityDetail",
      params: {id: `${row.id!}`},
      query: {},
    });
  }

  hasDisplayPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
}
