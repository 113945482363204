
























import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {
  CmsFriendLinkDto,
  CmsFriendLinkCreateOrUpdateDto,
  AttachmentHostType,
  TrainApplyCheckInPostDto,
  ServiceApplyCheckInRecordDto,
  ServiceApplyCheckInPostDto,
  ExamMakeUpInputDto
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "EditFriendLink",
  components: { },
})
export default class EditFriendLink extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  defaultData: ExamMakeUpInputDto = {
    id: 0,
    makeUpExaminationReason: undefined,
  };

  show = false;
  form: ExamMakeUpInputDto = { ...this.defaultData };

  get hostType() {
    return AttachmentHostType.FriendlyLinkLogo;
  }

  get title() {
    return `补考机会下发`;
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });

      this.form = { ...this.defaultData };
      //
      setTimeout(() => {
        loading.close();
      }, 200);
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    console.log(this.form);
    console.log(this.form2);

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.trainApply.makeUpPaper({body:{id:this.dataId,makeUpExaminationReason:this.form.makeUpExaminationReason}})
        this.show = false;
        this.$message.success("补考信息已发生至考生站内信");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    makeUpExaminationReason: [
      {
        required: true,
        message: "请输入补考原因",
        trigger: "blur",
      },
    ],
  };
}
