























































































































































































































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {ElForm} from "element-ui/types/form";
import {
  AttachmentHostType,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto, ServiceOrganizationDto, ServiceStatus,
  StarRatingStatus, UserDto
} from "@/api/appService";
import api from "@/api";
import multipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleVideoUpload from "@/components/SimpleVideoUpload/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";
import UserSelect from "@/components/UserSelect/index.vue";

import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import {CodeToText, regionData} from "element-china-area-data";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ElOptionSpan from "@/components/AbSelect/elOptionSpan.vue";
import ProvinceCityArea from "@/components/ProvinceCityArea/index.vue";
import ProvinceCityAreaEdit from "@/components/ProvinceCityArea/provinceCityAreaEdit.vue";

Vue.use(regionData, CodeToText);

@Component({
  components: {
    ProvinceCityAreaEdit,
    ProvinceCityArea,
    AttachmentsView,
    SimpleVideoUpload,
    SimpleUploadImage,
    multipleUploadFile,
    Ueditor,
    BaiduMap,
    UserSelect,
    ElOptionSpan
  }
})

export default class EditCmsContent extends Vue {
  @Ref() readonly dataForm!: ElForm;
  editId?: number = 0;
  isHelpApplies = "";
  submitting = false;
  type: any = {};
  selectedOptions: any[] = ["", "", ""];
  options: any = regionData;
  isOrganization = true;

  starLevelList: DataDictionaryDto[] = [];
  leaderList: any = [];
  serviceObjectTypeList: DataDictionaryDto[] = [];
  serviceOrganizationList: ServiceOrganizationDto[] = [];
  serviceObjectList: any = [];
  serviceTypeList: DataDictionaryDto[] = [];

  applyTimeTypes: any[] = [
    {label: "默认服务开始时间", value: 0},
    {label: "自定义", value: 1}
  ];
  YesOrNo: any = [{name: "是", value: true}, {name: "否", value: false}];
  selectAll = false;
  totalHours = 0;

  form: any = {
    id: 0,
    serviceObjectTypeId: undefined,
    serviceObjectIds: undefined,
    serviceStartTime: undefined,
    serviceEndTime: undefined,
    totalMinutes: 0,
    applyEndTime: undefined,
    titleImagePath: undefined,
    title: undefined,
    content: undefined,
    typeId: undefined,
    host: undefined,
    point: undefined,
    placeCoordinate: undefined,
    provinceId: undefined,
    cityId: undefined,
    areaId: undefined,
    address: undefined,
    minLevel: undefined,
    minPoint: 0,
    aimAmount: 0,
    status: ServiceStatus.Saved,
    isOffline:false,
    leaderUser: {
      surname: undefined
    },
    leaderId: undefined,
    applyTimeType: 0,
    needApprove: true
  };

  //初始服务对象ids
  initServiceObjectIds: number[] = [];

  userLocation: any = {
    lng: undefined,
    lat: undefined
  };
  provinceCityArea = "";
  address_detail = "";
  zoom = 13;
  BMap: any;
  map: any;

  loading = true;
  th = this;

  oldApplyEndTime: any;
  lastApplyEndTime: any;
  oldEndTime: any;
  lastEndTime: any;

  activeNames = ["1"];
  handleSearchServiceObject(leaderID:any){
    api.serviceMaintainService.getServiceObjectByLeaderId({leaderId:leaderID}).then((res)=>{
      if (res){
        const ids = res.split(",").map(Number);
        const needPushIds = ids.filter(id => !this.initServiceObjectIds.includes(id));
        this.form.serviceObjectIds = needPushIds.concat(this.initServiceObjectIds);
      }else{
        this.form.serviceObjectIds = this.initServiceObjectIds;
      }
    })
  }
  checkTime(time: any) {
    if ((new Date(time)).getTime() < (new Date(this.oldApplyEndTime)).getTime() && this.oldApplyEndTime) {
      this.$message.error('报名截止时间只能增加，不能减少！')
      this.form.applyEndTime = this.lastApplyEndTime ? this.lastApplyEndTime : moment(this.oldApplyEndTime).format('YYYY-MM-DD HH:mm:ss')
    } else if ((new Date(time)).getTime() > (new Date(this.form.serviceEndTime)).getTime()) {
      this.$message.error('报名截止时间不能晚于服务结束时间！')
      this.form.applyEndTime = this.lastApplyEndTime ? this.lastApplyEndTime : moment(this.oldApplyEndTime).format('YYYY-MM-DD HH:mm:ss')
    } else {
      this.form.applyEndTime = time
      this.lastApplyEndTime = time
    }
  }


  @Watch("form.address")
  changeMap(val: any) {
    //this.address_detail += val
    this.address_detail = this.provinceCityArea + val;
    this.setPlace();
  }

  get hostTypeFile() {
    return AttachmentHostType.ServiceRecord;
  }

  get dd() {
    return [this.form!.serviceStartTime, this.form!.serviceEndTime];
  }

  set dd(e) {
    if (e && e.length === 2) {
      if (new Date(e[1]).getTime() < new Date(this.oldEndTime).getTime()) {
        this.$message.error('服务结束时间只能增加，不能减少！')
        this.form.serviceEndTime = this.lastEndTime ? this.lastEndTime : moment(this.oldEndTime).format('YYYY-MM-DD HH:mm:ss')
      } else {
        console.log(e);
        (this.form as any)!.serviceStartTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        (this.form as any)!.serviceEndTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
        this.lastEndTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
        this.$forceUpdate();
      }
    }
  }

  get t1() {
    return moment(this.form!.serviceStartTime).format("HH:mm");
  }

  set t1(e) {
    if (e) {
      let _t = e.split(":");
      (this.form as any)!.serviceStartTime = moment(this.form!.serviceStartTime)
        .hour(parseInt(_t[0]))
        .minute(parseInt(_t[1]))
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(this.form!.serviceStartTime);
    }
  }

  get t2() {
    return moment(this.form!.serviceEndTime).format("HH:mm");
  }

  set t2(e) {
    if (e) {
      let _t = e.split(":");
      (this.form as any)!.serviceEndTime = moment(this.form!.serviceEndTime)
        .hour(parseInt(_t[0]))
        .minute(parseInt(_t[1]))
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(this.form!.serviceEndTime);
    }
  }

  @Watch("t1")
  onActivityStartTimeChange(value: any) {
    if (value) {
      let difDays = moment(this.form!.serviceEndTime).diff(
        moment(this.form!.serviceStartTime),
        "days"
      );
      let difMin = moment(this.form!.serviceEndTime).diff(
        moment(this.form!.serviceStartTime),
        "minutes"
      );

      console.log(`difDays:${difDays},difMin:${difMin}`);

      this.totalHours = (difMin - difDays * 24 * 60) / 60;
    }
  }

  @Watch("t2")
  onActivityEndTimeChange(value: any) {
    if (value) {
      let difDays = moment(this.form!.serviceEndTime).diff(
        moment(this.form!.serviceStartTime),
        "days"
      );
      let difMin = moment(this.form!.serviceEndTime).diff(
        moment(this.form!.serviceStartTime),
        "minutes"
      );

      console.log(`difDays:${difDays},difMin:${difMin}`);

      this.totalHours = (difMin - difDays * 24 * 60) / 60;
    }
  }

  map_handler(result: any) {
    this.BMap = result.BMap;
    this.map = result.map;
  }

  //地址
  setPlace() {
    let th1 = this.th;
    let map = this.map;
    let BMap = this.BMap;
    this.map.clearOverlays(); //清除地图上所有覆盖

    function myFun() {
      console.log(local.getResults().getPoi(0).point);
      th1.userLocation = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
      map.centerAndZoom(th1.userLocation, 18);
      map.addOverlay(new BMap.Marker(th1.userLocation)); //添加标注
      th1.form!.placeCoordinate = "" + th1.userLocation.lng + "," + th1.userLocation.lat;
    }

    let local = new this.BMap.LocalSearch(this.map, { //智能搜索
      onSearchComplete: myFun
    });
    local.search(this.address_detail);
    console.log(this.address_detail);
    this.map.addEventListener("click", function (e: any) {

      th1.form!.placeCoordinate = "" + th1.userLocation.lng + "," + th1.userLocation.lat;
    });
  }

  created() {
    //
    if (this.$route.params.id) {
      this.editId = Number(this.$route.params.id);
      this.isHelpApplies = String(this.$route.query.isHelpApplies);

      this.fetchDetail();
      // api.starRating.get({id: this.editId}).then(res => {
      //   this.form = {...res};
      //   this.loading = false;
      // })
    }
    this.fetchDictionary();
    this.getLeaderList();
    this.getServiceObjectList();
  }
  setAddress(address:any){
    this.provinceCityArea=address
    console.log("this.provinceCityArea",this.provinceCityArea)
  }

  roleRule = {
    title: [{
      required: true,
      message: "请填写服务标题",
      trigger: "blur"
    }],
    serviceObjectTypeId: [{
      required: true,
      message: "请选择服务对象类型",
      trigger: "blur"
    }],
    serviceOrganizationId: [{
      required: true,
      message: "请选择服务机构",
      trigger: "blur"
    }],
    serviceObjectIds: [{
      required: true,
      message: "请选择服务对象",
      trigger: "blur"
    }],
    content: [
      {
        required: true,
        message: "请填写服务详情",
        trigger: "blur"
      }
    ],
    typeId: [
      {
        required: true,
        message: "请选择服务类型",
        trigger: "change"
      }
    ],
    serviceEndTime: [
      {
        required: true,
        message: "请选择服务日期",
        trigger: "blur"
      }
    ],
    managerId: [
      {
        required: true,
        message: "请选择负责人",
        trigger: "change"
      }
    ],

    titleImagePath: [
      {
        required: true,
        message: "请上传封面图片",
        trigger: "blur"
      }
    ],isOffline: [
      {
        required: true,
        message: "请选择服务发布类型",
        trigger: "blur"
      }
    ],
    leaderId: [
      {
        required: true,
        message: "请填写领队",
        trigger: "change"
      }
    ],

    // applyEndTime: [{
    //   required: true,
    //   message: '请填写报名截止时间',
    //   trigger: 'blur'
    // }],

    totalMinutes: [{
      required: true,
      message: "请填写单次有效服务时长",
      trigger: "blur"
    }],
    areaId: [{
      required: true,
      message: "请填写服务地点",
      trigger: "blur"
    }],
    point: [{
      required: true,
      message: "请填写单次积分",
      trigger: "blur"
    }]
  };
  // 获取详情
  async fetchDetail() {
    if (this.isHelpApplies == "true") {
      await api.helpApplies.get({id: this.editId}).then(res => {
        this.form = {...this.form, ...res};
        this.form.id = 0;
        if (res.serviceObjectIds){
          this.form.serviceObjectIds = res.serviceObjectIds!.split(",").map(Number);
          this.initServiceObjectIds = res.serviceObjectIds!.split(",").map(Number);
        }


        this.isOrganization = (res.serviceObjectType?.displayName == "机构");
        this.form.serviceOrganizationId=res.serviceOrgId
        this.loading = false;
        this.$set(this.form, 'applyTimeType', 0);
        this.$set(this.form, 'title', res.title);
        this.$set(this.form, 'content', res.content);
        this.$set(this.form, 'helpApplyId', this.editId);

        console.log("this.form",this.form);
        // this.form!.serviceStartTime = ''
        // this.form!.serviceEndTime = ''
        this.selectedOptions = [
          res!.provinceId + "",
          res!.cityId + "",
          res!.areaId + ""
        ];
        if (!res.provinceId){
          // 删除 roleRule 对象中的 areaId 项
           this.roleRule.areaId[0].required=false;
        }
        this.provinceCityArea = (res!.province!.provinceName ?? "" + "") + (res!.city!.cityName ?? "" + "") + (res!.area!.areaName ?? "" + "");
        this.address_detail = this.provinceCityArea + res.address;
      });
    } else {
      await api.serviceMaintainService.get({id: this.editId}).then(res => {
        this.form = {...res};
        this.loading = false;
        this.$set(this.form, 'applyTimeType', 1);
        this.$set(this.form, 'applyEndTime', moment(res.applyEndTime).format('YYYY-MM-DD HH:mm:ss'));
        this.totalHours = res.totalMinutes! / 60;
        this.form.serviceObjectIds = (res.serviceObjectIds!.split(",")).map(m => Number(m));
        this.isOrganization = (res.serviceObjectType?.displayName == "机构");
        this.oldApplyEndTime = res.applyEndTime!;
        this.oldEndTime = res.serviceEndTime!;
        console.log(this.form.serviceObjectIds);
        this.selectedOptions = [
          res!.provinceId + "",
          res!.cityId + "",
          res!.areaId + ""
        ];
        console.log(this.form);

        this.provinceCityArea = (res!.province!.provinceName ?? "" + "") + (res!.city!.cityName ?? "" + "") + (res!.area!.areaName ?? "" + "");
        this.address_detail = this.provinceCityArea + res.address;
      });
    }
  }

  //   省市区
  handleChange(value: any) {
    this.form!.provinceId = Number(value[0]);
    this.form!.cityId = Number(value[1]);
    this.form!.areaId = Number(value[2]);
    this.address_detail = CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]];
    console.log(this.address_detail);
    this.provinceCityArea = CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]];
    console.log(this.provinceCityArea);
    this.setPlace();
  }

  changeSelect(value: any) {
    if (this.selectAll) {
      this.selectAll = false;
      if (value.indexOf("selectAll") > -1) {
        this.form.serviceObjectIds = value.filter((p: any) => p != "selectAll");
      } else {
        this.form.serviceObjectIds = [];
      }
    } else {
      //   是否点击了‘全选’选项
      if (value.indexOf("selectAll") > -1) {
        // 有‘全选’选项，则将‘全部’和其他值放置一块
        const optionsValue: any = [];
        this.serviceObjectList.forEach((item: any) => {
          console.log(item);
          optionsValue.push(item.id);
        });
        this.form.serviceObjectIds = ["selectAll", ...optionsValue];
        this.selectAll = true;
      } else {
        // 若是勾选选择的长度和提供的选项长度是一样的，则是 ‘全选’
        if (value.length === this.serviceObjectList.length) {
          const optionsValue: any = [];
          this.serviceObjectList.forEach((item: any) => {
            optionsValue.push(item.id);
          });
          this.form.serviceObjectIds = ["selectAll", ...optionsValue];
          this.selectAll = true;
        } else {
          //   都是单选
          this.form.serviceObjectIds = value;
        }
      }
    }
    // 真实的勾选值
    const realSelect = this.form.serviceObjectIds.filter((item: any) => item != "selectAll");
    console.log("realSelect", realSelect);
    console.log("serviceObjectIds", this.form.serviceObjectIds);
  }


  //服务对象类型
  handleTypeChange(value: any) {
    console.log(value);
    for (let i = 0; i < this.serviceObjectTypeList.length; i++) {
      if (this.serviceObjectTypeList[i].id == value) {
        if (this.serviceObjectTypeList[i].displayName != "机构") {
          this.isOrganization = false;
        } else {
          this.isOrganization = true;
        }
      }
    }
  }

  async fetchDictionary() {
    //志愿者星级
    await api.dataDictionary.getDataDictionaryListByKey({
      maxResultCount: 65535,
      key: "VolunteerLevel"
    }).then((res: DataDictionaryDtoPagedResultDto) => {
      this.starLevelList = res.items!;
    });
    //服务对象类型
    await api.dataDictionary.getDataDictionaryListByKey({
      key: "ServiceObjectType",
      maxResultCount: 65535
    }).then((res: DataDictionaryDtoPagedResultDto) => {
      this.serviceObjectTypeList = res.items!;
    });
    //服务机构
    await api.serviceOrganization.getAllServiceOrganizationList().then((res) => {
      this.serviceOrganizationList = res!;
    });
    //服务类型
    await api.dataDictionary.getDataDictionaryListByKey({
      key: "ServiceType",
      maxResultCount: 65535
    }).then((res: DataDictionaryDtoPagedResultDto) => {
      this.serviceTypeList = res.items!;
    });
  }

  async getLeaderList() {
    await api.volunteer.getApprovedVolunteerList().then((res: UserDto[]) => {
      this.leaderList = [...res];
    })
  }

  async getServiceObjectList() {
    await api.serviceObject.getAll({maxResultCount:65535}).then((res) => {
      this.serviceObjectList = res.items!;
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  //
  // back() {
  //   this.$router.go(-1)
  // }

  private async save(isPublish: boolean) {
    console.log(this.form);
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (!this.form.content) {
          this.$message({
            type: "error",
            message: "请填写服务详情"
          });
          return;
        }

        if (this.form.applyTimeType == 0) {
          this.form.applyEndTime = this.form.serviceStartTime;
        }

        if (isPublish) {
          this.form.status = ServiceStatus.Published;
        } else {
          this.form.status = ServiceStatus.Saved;
        }

        this.form.serviceObjectIds = this.form.serviceObjectIds.join(",");
        this.form.totalMinutes = Number(this.totalHours * 60);

        (this as any).$bus.$emit("before-save");
        this.submitting = true;
        let fn = undefined;
        if (this.form!.id || this.form!.id > 0) {
          console.log(this.form.serviceObjectIds);
          fn = api.serviceMaintainService.update;
        } else {
          fn = api.serviceMaintainService.create;
        }
        await fn({body: this.form}).then(res => {
          this.$message({
            type: "success",
            message: "操作成功"
          });
          setTimeout(() => {
            this.$router.push({name: "serviceMaintain"});
          }, 200);
        }).catch(() => {
          this.submitting = false;
        });
      } else {
        this.$message({
          type: "error",
          message: "请检查表单"
        });
      }
    });
  }

}
