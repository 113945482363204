




























































































































import api from "@/api";
import {Component, Vue, Ref} from "vue-property-decorator";
import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  BbsGroupDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  components: {ExportButton, PagedTableView},
})
export default class BbsGroupList extends Vue {
  @Ref() readonly pagedTableView!: any;
  bbsGroupId = 0;
  queryForm = {
    name: "",
    groupTypeId: undefined,
  };
  BbsGroupTypeList: DataDictionaryDto[] = [];

  fetchData(params: any) {
    // debugger;

    return api.bbsGroup.getAll(params);
  }

  created() {
    this.fetchBbsGroupTypeList();
  }

  async fetchBbsGroupTypeList() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "BbsGroupType",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.BbsGroupTypeList = res!.items!;
      });
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "bbsGroupCreate",
    });
  }

  // 跳转编辑页
  handleEdit(index: number, row: BbsGroupDto) {
    this.$router.push({
      name: "bbsGroupEdit",
      params: {id: row.id!.toString()},
    });
  }

  // 跳转群组成员页
  handleGroupUserDetail(index: number, row: BbsGroupDto) {
    this.$router.push({
      name: "bbsGroupUser",
      params: {id: row.id!.toString()},
      query: {},
    });
  }

  //禁用群组
  handleDisable(index: number, row: BbsGroupDto) {
    this.$confirm("您确定禁用群组吗?", "提示").then(() => {
      api.bbsGroup.disable({id: row.id}).then((res) => {
        this.$message.success("禁用群组成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  //启用群组
  handleEnable(index: number, row: BbsGroupDto) {
    this.$confirm("您确定启用群组吗?", "提示").then(() => {
      api.bbsGroup.enable({id: row.id}).then((res) => {
        this.$message.success("启用群组成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  // 删除
  handleDelete(item: number, row: BbsGroupDto) {
    this.$confirm("您确定删除群组吗?", "提示").then(() => {
      api.bbsGroup.delete({id: row.id}).then((res) => {
        this.$message.success("删除成功");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
