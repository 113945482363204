





























































import {Ref, Vue} from "vue-property-decorator";
import {Component} from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";
import api from "@/api";
import ExportButton from "@/components/ExportButton/index.vue";
import JSZip from "jszip";
import {saveAs} from "file-saver";

@Component({
  components: {ExportButton, PagedTableView}
})
export default class ServiceFeelingsList extends Vue {
  queryForm = {
    serviceId: this.$route.params.serviceId,
    surname: ""
  }

  @Ref() readonly pagedTableView!: any;
  selection: any = [];

  async fetchData(params: any) {
    params.serviceId = this.$route.params.serviceId;
    return await api.serviceRecordFeedback.getAll(params)
  }

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selection = rows;
    console.log(this.selection)
  }
  handleExport(): void {
    if (this.selection && this.selection.length > 0) {
      const zip = new JSZip();
      const promises: Promise<void>[] = [];

      this.selection.forEach((res:any, index:number) => {
        const folder = zip.folder(`${res.creatorUser.surname}${index + 1}`);

        const imageUrl: string[] = JSON.parse(res.photoUrls);
        imageUrl.forEach((img, imgIndex) => {
          const imgTypeIndex: number = img.lastIndexOf(".");
          const extension: string = img.substring(imgTypeIndex);
          const fileName = `${res.creatorUser.surname}${(imgIndex+1)}${extension}`;

          promises.push(
            fetch(img, { mode: 'cors' })
              .then((response: Response) => response.blob())
              .then((blob: Blob) => {
                if (folder) {
                  debugger
                  console.log(blob)
                  folder.file(fileName, blob);
                }
              })
              .catch((error: any) => {
                console.error(`Error fetching ${img}`, error);
              })
          );
        });
      });

      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then((blob: Blob) => {
          saveAs(blob, "就业人员详细信息.zip");
        });
      });
    }
  }



}
